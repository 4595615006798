import { createAppAsyncThunk } from '@/store/createAppThunk.ts'
import { FileEntity } from '@/modules/document-analysis/domain/entities/FileEntity.ts'
import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { AnalyzeDpaResponseDto } from '@/modules/document-analysis/schemas.ts'
import { addConversation } from '@/store/conversations/conversationsSlice.ts'
import { analyzeDpaMapper } from '@/modules/document-analysis/mappers/analyzeDpaMapper.ts'
import { setToasterMessage } from '@/store/toasterSlice.ts'
import { ERROR_MESSAGES } from '@/utils/errorMessages.ts'

export const analyzeDpaUseCase = createAppAsyncThunk<ConversationEntity, { file: FileEntity }>(
  'documentAnalysis/analyzeDpaUseCase',
  async ({ file }, { dispatch, extra: { documentAnalysisGateway }, rejectWithValue }) => {
    try {
      const mapper = analyzeDpaMapper()
      const response: AnalyzeDpaResponseDto = await documentAnalysisGateway.analyzeDpa({ file })
      const newConv = mapper.toEntity(response)
      dispatch(addConversation(newConv))
      return newConv
    } catch (error) {
      dispatch(
        setToasterMessage({
          toasterMessage: ERROR_MESSAGES.GENERIC,
          toasterType: 'generic',
        }),
      )
      return rejectWithValue(ERROR_MESSAGES.GENERIC)
    }
  },
)
