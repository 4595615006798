import { z } from 'zod'
import { ConversationType } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { MessageFeedbackEnum } from '@/modules/message/domain/entities/MessageEntity.ts'

export const AnalyzeDpaResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal(ConversationType.ANALYZE),
    currentFilesInfos: z.array(
      z.object({
        title: z.string(),
      }),
    ),
    createdAt: z.string(),
    messages: z.array(
      z.object({
        content: z.string(),
        sourceCollections: z.array(z.unknown()),
        feedback: z.literal(MessageFeedbackEnum.NULL),
        id: z.string(),
        sources: z.array(z.unknown()),
        createdAt: z.string(),
        type: z.string(),
      }),
    ),
  }),
})
export type AnalyzeDpaResponseDto = z.infer<typeof AnalyzeDpaResponseSchema>

export const ExampleDpaResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    name: z.string(),
    type: z.literal(ConversationType.ANALYZE),
    currentFilesInfos: z.array(
      z.object({
        title: z.string(),
      }),
    ),
    createdAt: z.string(),
    messages: z.array(
      z.object({
        content: z.string(),
        sourceCollections: z.array(z.unknown()),
        feedback: z.literal(MessageFeedbackEnum.NULL),
        id: z.string(),
        sources: z.array(z.unknown()),
        createdAt: z.string(),
        type: z.string(),
      }),
    ),
  }),
})
export type ExampleDpaResponseDto = z.infer<typeof ExampleDpaResponseSchema>
