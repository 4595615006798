import { Button } from '@/components/ui/button.tsx'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  onClick?: () => void
  disabled: boolean
}

function DpaFormButton({ children, onClick, disabled }: Readonly<Props>) {
  return (
    <Button onClick={onClick} className="h-full w-full" variant="outline" disabled={disabled}>
      {children}
    </Button>
  )
}

export default DpaFormButton
