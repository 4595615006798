import type React from 'react'
import { Drawer, DrawerContent, DrawerTitle } from '@/components/ui/drawer.tsx'

interface BottomDrawerProps {
  isOpen: boolean
  onClose: (value: boolean) => void
  children: React.ReactNode
}

export function BottomDrawer({ isOpen, onClose, children }: Readonly<BottomDrawerProps>) {
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(value) => {
        onClose(value)
      }}
      direction="bottom"
    >
      <DrawerTitle></DrawerTitle>
      <DrawerContent aria-describedby={undefined} className="bg-softMint">
        <div className="over h-[50vh] overflow-y-auto">
          <div className="p-4">{children}</div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}
