import { SourceReadingInfos } from '@/types/source.ts'
import { TheSourcesListItem } from '@/components/TheSourcesListItem.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import { selectSources } from '@/store/messages/messagesSlice.ts'

export function TheSourcesList() {
  const sources = useAppSelector(selectSources)
  return (
    <div className={'flex flex-col'}>
      <span className={'mb-2 mr-auto text-sm font-semibold'}>Pour aller plus loin : </span>
      <div className={'flex flex-col items-center justify-center gap-2'}>
        {sources.map((source: SourceReadingInfos, index) => (
          <TheSourcesListItem key={index} sourceNumber={index + 1} source={source} />
        ))}
      </div>
    </div>
  )
}
