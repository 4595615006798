import { useEffect } from 'react'
import { WretchAccountGateway } from '@/gateways/WretchAccount.gateway.ts'
import { simpleGPTApiWretch } from '@/gateways/olympeGptApiWretch.ts'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { setCurrentUser } from '@/store/auth/authSlice.ts'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@/store/hooks.ts'

export function useFetchUser() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchUser = async () => {
      const accountGateway = new WretchAccountGateway(simpleGPTApiWretch)
      const currentUser = await accountGateway.getCurrentUser()

      if (currentUser?.emailValidated === false) {
        navigate(`${ROUTE_PATHS.accountVerificationInfo}?email=${currentUser.email}`)
      }
      if (currentUser?.emailValidated) {
        dispatch(setCurrentUser(currentUser))
        navigate(ROUTE_PATHS.greetingUser)
      }
    }

    fetchUser()
  }, [navigate, dispatch])
}
