import { OlympeGptApiWretch } from '@/gateways/olympeGptApiWretch'
import { DocumentAnalysisGateway } from '@/modules/document-analysis/domain/document-analysis.gateway.ts'
import { FileEntity } from '../domain/entities/FileEntity'
import FormDataAddon from 'wretch/addons/formData'
import {
  AnalyzeDpaResponseDto,
  AnalyzeDpaResponseSchema,
  ExampleDpaResponseDto,
} from '@/modules/document-analysis/schemas.ts'
import { GATEWAY_ERRORS, ERROR_MESSAGES } from '@/utils/errorMessages.ts'
import { DpaExamples } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'

export class WretchDocumentAnalysisGateway implements DocumentAnalysisGateway {
  private readonly endpoint = '/conversations'
  private readonly olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async analyzeDpa({ file }: { file: FileEntity }): Promise<AnalyzeDpaResponseDto> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/dpa`

    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .addon(FormDataAddon)
      .formData({ file: file.file })
      .post()
      .internalError(() => {
        throw new Error(GATEWAY_ERRORS[500])
      })
      .json()

    const { data, error } = AnalyzeDpaResponseSchema.safeParse(response)
    if (error) throw new Error(ERROR_MESSAGES.GENERIC)
    return Promise.resolve(data)
  }

  async fetchExampleDpaUseCase({ example }: { example: DpaExamples }): Promise<ExampleDpaResponseDto> {
    const requestInfoOrUrl: RequestInfo | URL =
      `${this.endpoint}/dpa/example?exampleFilename=${encodeURIComponent(example)}`

    const response = await this.olympeGptApi
      .url(requestInfoOrUrl)
      .get()
      .internalError(() => {
        throw new Error(GATEWAY_ERRORS[500])
      })
      .json()

    const { data, error } = AnalyzeDpaResponseSchema.safeParse(response)
    if (error) throw new Error(ERROR_MESSAGES.GENERIC)
    return Promise.resolve(data)
  }
}
