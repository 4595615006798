import { createEntityAdapter, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { Conversation } from '@/types/conversation.ts'
import { listConversationMessages } from './useCases/listConversationMessages.ts'
import { RootState } from '../configureStore.ts'
import { Message } from '@/types/message.ts'
import { createConversationMessage } from '@/store/messages/useCases/createConversationMessage.ts'
import { selectActiveConversationId } from '@/store/conversations/conversationsSlice.ts'
import { updateMessageFeedback } from './useCases/updateMessageFeedback.ts'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'

export const messagesEntityAdapter = createEntityAdapter<MessageEntity>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
})
export const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesEntityAdapter.getInitialState({
    isLoading: false,
    isAnalyzingDpa: false,
    isStreaming: false,
    createMessageError: '',
    listConversationMessagesError: '',
    sources: [] as Message['sources'],
  }),
  reducers: {
    addMessage: (state, action: PayloadAction<MessageEntity>) => {
      messagesEntityAdapter.addOne(state, action.payload)
    },
    resetMessages: (state) => {
      messagesEntityAdapter.setAll(state, [])
    },
    setIsStreaming: (state, action: PayloadAction<boolean>) => {
      state.isStreaming = action.payload
    },
    streamLastMessageInConversation: (
      state,
      action: PayloadAction<{ conversationId: Conversation['id']; token: string }>,
    ) => {
      const lastMessageId = state.ids.pop()
      if (!lastMessageId) return
      const lastMessage = state.entities[lastMessageId]
      if (!lastMessageId) return

      messagesEntityAdapter.updateOne(state, {
        id: lastMessageId,
        changes: {
          content: lastMessage?.content + action.payload.token,
        },
      })
    },
    setSources: (state, action: PayloadAction<Message['sources']>) => {
      state.sources = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listConversationMessages.fulfilled, (state, action) => {
      state.isLoading = false
      const { messages, initialQuestion } = action.payload

      const temporaryMessages = state.ids.filter((id) => id === 'stream-human-id' || id === 'stream-id')

      // Do not delete temporary messages if there is an initial question
      if (!initialQuestion) {
        temporaryMessages.forEach((id) => {
          messagesEntityAdapter.removeOne(state, id)
        })
      }

      messagesEntityAdapter.upsertMany(state, messages)
    })
    builder.addCase(listConversationMessages.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(listConversationMessages.rejected, (state, action) => {
      state.isLoading = false
      state.listConversationMessagesError = action.error.message ?? ''
    })
    builder.addCase(updateMessageFeedback.fulfilled, (state, action) => {
      state.isLoading = false
      messagesEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      })
    })
    builder.addCase(createConversationMessage.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createConversationMessage.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createConversationMessage.rejected, () => {})
  },
})

export const { addMessage, resetMessages, streamLastMessageInConversation, setSources, setIsStreaming } =
  messagesSlice.actions

export const messagesSelector = messagesEntityAdapter.getSelectors((state: RootState) => state.entities.messages)

export const selectAllMessages = (state: RootState) => messagesSelector.selectAll(state)

export const selectMessagesForActiveConversation = createSelector(
  [selectActiveConversationId, selectAllMessages],
  (activeConversationId, messages) => {
    if (!activeConversationId) return []
    return messages.filter((message) => {
      return message.conversationId === activeConversationId
    })
  },
)

export const selectIsStreaming = (state: RootState) => state.entities.messages.isStreaming
export const selectSources = (state: RootState) => state.entities.messages.sources
