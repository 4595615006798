import { RegisterPageRightPart } from '@/components/registerSteps/credentials/right/RegisterPageRightPart.tsx'
import { RegisterPageLeftPart } from '@/components/registerSteps/credentials/left/RegisterPageLeftPart.tsx'
import { useFetchUser } from '@/components/hooks/use-FetchUser.hook.tsx'

export function RegisterPage() {
  useFetchUser()

  return (
    <div className={'flex min-h-screen p-7 xl:max-h-screen xl:gap-6 2xl:mx-auto 2xl:max-w-1400'}>
      <RegisterPageLeftPart />
      <RegisterPageRightPart />
    </div>
  )
}
