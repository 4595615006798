import { LockIcon } from 'lucide-react'

function DpaFormButtonSoon() {
  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex h-full w-full justify-center">
        <div className="flex items-center gap-2 opacity-60">
          <LockIcon className="h-4 w-4 stroke-primary/60" />
          <span className={'text-base text-primary'}>Bientôt disponible 🚀</span>
        </div>
      </div>
    </div>
  )
}
export default DpaFormButtonSoon
