import { selectConversationById } from '@/store/conversations/conversationsSlice.ts'
import { deleteFilesInConversation } from '@/store/conversations/useCases/deleteFilesInConversation.ts'
import { uploadFileInConversation } from '@/store/conversations/useCases/uploadFileInConversation.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { Conversation } from '@/types/conversation.ts'
import { useDetectScreenSize } from '@/utils/useDetectScreenSize.ts'
import { ChangeEvent, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  selectIsDrawerDisplayed,
  selectIsMobileSourceFiltersDrawerDisplayed,
  setIsMobileSourceFiltersDrawerDisplayed,
} from '@/store/drawers/drawersSlice.ts'

export const useConversationPageHook = () => {
  const dispatch = useAppDispatch()
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }
  const isLegalWatchDrawerDisplayed = useAppSelector(selectIsDrawerDisplayed)
  const conversation = useAppSelector(selectConversationById(conversationId))
  const isMobileSourceFiltersDrawerDisplayed = useAppSelector(selectIsMobileSourceFiltersDrawerDisplayed)

  const [userInput, setUserInput] = useState('')

  const [isQuestionLoading, setIsQuestionLoading] = useState(false)
  const systemMessageContent =
    conversation?.currentFilesInfos.length === 2
      ? 'Analyse des documents suivants en cours :'
      : 'Analyse du document suivant en cours :'
  const isMobile = useDetectScreenSize()

  const targetRef = useRef<HTMLDivElement>(null)

  const handleUpload = async (file: File) => {
    await dispatch(uploadFileInConversation({ conversationId, file, fileName: file.name }))
  }
  const handleStopFileContext = async () => {
    await dispatch(deleteFilesInConversation(conversationId))
  }
  const handleUserInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserInput(e.target.value)
  }
  const handleDisplayFilterMobile = (open: boolean) => {
    dispatch(setIsMobileSourceFiltersDrawerDisplayed(open))
  }

  const resetUserInput = () => {
    setUserInput('')
  }

  return {
    isMobile,
    conversation,
    targetRef,
    setUserInput,
    userInput,
    isQuestionLoading,
    setIsQuestionLoading,
    isMobileSourceFiltersDrawerDisplayed,
    systemMessageContent,
    handleUpload,
    handleStopFileContext,
    handleUserInput,
    handleDisplayFilterMobile,
    isLegalWatchDrawerDisplayed,
    resetUserInput,
  }
}
