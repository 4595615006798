import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/lib/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-bright hover:bg-primary/90 hover:cursor-pointer disabled:bg-bInput disabled:text-gray-500 disabled:cursor-not-allowed',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary: 'bg-inherit text-label hover:text-primary hover:bg-inherit',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        tertiary:
          'bg-bright text-base text-primary border-primary border-solid border-[1px] hover:cursor-pointer hover:border-label hover:text-label',
        link: 'text-primary',
        filter: 'hover:bg-gainsboroGray',
        boutonDefault:
          'bg-mintGreen text-white hover:bg-electra focus:bg-mintGreen focus:ring-mintGreen disabled:bg-bInput disabled:text-gray-500 disabled:cursor-not-allowed',
        disabledOutline:
          'border border-input bg-background text-primary/60 disabled:cursor-not-allowed disabled:pointer-events-none !pointer-events-auto',
        minimal: 'bg-inherit text-primary font-normal hover:cursor-pointer',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
        roundedIcon: 'h-10 w-10 rounded-full',
        filter: 'px-3.5 py-1',
        ghost: 'p-0 w-fit',
        boutonLarge: 'py-3 px-7 gap-3',
        boutonMedium: 'py-3 px-6 gap-3',
        boutonSmall: 'py-2 px-4 gap-3',
        minimal: 'p-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
  },
)
Button.displayName = 'Button'

export { Button, buttonVariants }
