import { ComponentPropsWithoutRef } from 'react'

function TableHeadCellMarkdown(props: ComponentPropsWithoutRef<'th'>) {
  return (
    <th
      {...props}
      className="border-b-2 border-brightGray bg-brightGray px-6 py-3 text-left text-sm font-semibold text-flintRock"
    />
  )
}
export default TableHeadCellMarkdown
