import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { transformISOToDDMMYYYY } from '@/utils/date.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { RootState } from '@/store/configureStore.ts'

const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000

export const listArticles = createAppAsyncThunk<LegalWatchArticle[], void, { state: RootState }>(
  'articles/listArticles',
  async (_, { getState, extra: { legalWatchArticleGateway }, rejectWithValue }) => {
    const state = getState()
    const lastFetched = state.entities.articles.lastFetched

    // if the last fetch was less than 24 hours ago, we don't fetch again
    if (lastFetched && Date.now() - lastFetched < TWENTY_FOUR_HOURS) {
      return rejectWithValue('CACHE_VALID') // return a rejection with a special value that we can handle in the reducer
    }

    try {
      const result = await legalWatchArticleGateway.listLegalWatchArticles()
      return result.map((article) => ({
        ...article,
        createdAt: transformISOToDDMMYYYY(article.createdAt),
      }))
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
