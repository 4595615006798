import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { Conversation, CurrentFileInfos } from '@/types/conversation.ts'
import { RootState } from '../configureStore.ts'
import { createConversation } from './useCases/createConversation.ts'
import { deleteConversationById } from './useCases/deleteConversationById.ts'
import { deleteFilesInConversation } from './useCases/deleteFilesInConversation.ts'
import { getConversationById } from './useCases/getConversationById.ts'
import { listUserConversations } from './useCases/listUserConversations.ts'
import { updateConversation } from './useCases/updateConversation.ts'
import { uploadFileInConversation } from './useCases/uploadFileInConversation.ts'
import { listUserConversationsForArticle } from '@/store/articles/useCases/listUserConversationsForArticle.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'

const conversationsEntityAdapter = createEntityAdapter<Conversation>({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

type InitialState = {
  isLoading: boolean
  isUploadingFileInConversation: boolean
  isCreatingConversation: boolean
  createConversationError: string
  getConversationByIdError: string
  updateConversationError: string
  listConversationsError: string
  currentConversationType: 'MESSAGE_ONLY' | 'SINGLE_FILE' | 'FILE_COMPARISON' | 'ARTICLE' | 'ANALYZE'
  listSourceCollectionsError: string
  showUpdateUserModal: boolean
  conversationsForArticle: Conversation[]
  initialQuestion: string
  activeConversationId: Conversation['id'] | null
}

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: conversationsEntityAdapter.getInitialState<InitialState>({
    isLoading: false,
    isUploadingFileInConversation: false,
    isCreatingConversation: false,
    createConversationError: '',
    getConversationByIdError: '',
    updateConversationError: '',
    listConversationsError: '',
    currentConversationType: 'MESSAGE_ONLY',
    listSourceCollectionsError: '',
    showUpdateUserModal: false,
    conversationsForArticle: [],
    initialQuestion: '',
    activeConversationId: null,
  }),
  reducers: {
    addConversation: (state, action: { payload: ConversationEntity }) => {
      conversationsEntityAdapter.addOne(state, action.payload)
    },

    setInitialQuestion: (state, action: { payload: string }) => {
      state.initialQuestion = action.payload
    },

    setActiveConversationId: (state, action: { payload: LegalWatchArticle['slug'] }) => {
      state.activeConversationId = action.payload
    },

    showUpdateUserModal: (state) => {
      state.showUpdateUserModal = true
    },
    setConversationCurrentFileInfos: (
      state,
      action: {
        payload: {
          conversationId: Conversation['id']
          currentFileInfos: CurrentFileInfos
        }
      },
    ) => {
      conversationsEntityAdapter.updateOne(state, {
        id: action.payload.conversationId,
        changes: {
          currentFilesInfos: [
            {
              title: action.payload.currentFileInfos.title,
              uploadStatus: action.payload.currentFileInfos.uploadStatus,
            },
          ],
        },
      })
    },
    updateConversationSnapshotByConversationId: (
      state,
      action: { payload: { id: Conversation['id']; snapshotId: Conversation['snapshotId'] } },
    ) => {
      conversationsEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          snapshotId: action.payload.snapshotId,
        },
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUserConversations.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation[] = action.payload.map((conversation) => {
          return {
            ...conversation,
            currentFilesInfos: conversation.currentFilesInfos.map((fileInfos) => ({
              ...fileInfos,
              uploadStatus: 'UPLOADED',
            })),
          }
        })
        conversationsEntityAdapter.setAll(state, payload)
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.isLoading = false
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.addOne(state, payload)
        if (action.payload.type === 'ARTICLE') {
          state.conversationsForArticle = [payload]
        }
      })
      .addCase(createConversation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createConversation.rejected, (state, action) => {
        state.isLoading = false
        state.createConversationError = action.error.message ?? ''
      })
      .addCase(updateConversation.fulfilled, (state, action) => {
        state.currentConversationType = action.payload.type
        state.isLoading = false
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.upsertOne(state, payload)
      })
      .addCase(updateConversation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateConversation.rejected, (state, action) => {
        state.isLoading = false
        state.updateConversationError = action.error.message ?? ''
      })
      .addCase(getConversationById.fulfilled, (state, action) => {
        state.isLoading = false
        state.activeConversationId = action.payload.id
        const payload: Conversation = {
          ...action.payload,
          currentFilesInfos: action.payload.currentFilesInfos.map((fileInfos) => ({
            ...fileInfos,
            uploadStatus: 'UPLOADED',
          })),
        }
        conversationsEntityAdapter.upsertOne(state, payload)
      })
      .addCase(getConversationById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getConversationById.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(deleteConversationById.fulfilled, (state, action) => {
        const conversationId = action.meta.arg
        state.isLoading = false
        conversationsEntityAdapter.removeOne(state, conversationId)
      })
      .addCase(deleteConversationById.pending, (state, action) => {
        const conversationId = action.meta.arg
        // Remove the conversation from the list even if the request is not finished
        conversationsEntityAdapter.removeOne(state, conversationId)
        state.isLoading = true
      })
      .addCase(deleteConversationById.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(deleteFilesInConversation.fulfilled, (state, action) => {
        state.isLoading = false
        conversationsEntityAdapter.updateOne(state, {
          id: action.payload,
          changes: {
            currentFilesInfos: [],
          },
        })
      })
      .addCase(deleteFilesInConversation.pending, (state, action) => {
        state.isLoading = true
        conversationsEntityAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            currentFilesInfos: [],
          },
        })
      })
      .addCase(deleteFilesInConversation.rejected, (state, action) => {
        state.isLoading = false
        state.getConversationByIdError = action.error.message ?? ''
      })
      .addCase(uploadFileInConversation.fulfilled, (state, action) => {
        state.isUploadingFileInConversation = false

        const current = state.entities[action.payload.conversationId]?.currentFilesInfos
        const lastElement = current?.pop()
        if (lastElement && current) {
          const newLast: Conversation['currentFilesInfos'][0] = { ...lastElement, uploadStatus: 'UPLOADED' }
          conversationsEntityAdapter.updateOne(state, {
            id: action.payload.conversationId,
            changes: {
              currentFilesInfos: [...current, newLast],
            },
          })
        }
      })
      .addCase(uploadFileInConversation.rejected, (state, action) => {
        state.isUploadingFileInConversation = false

        const current = state.entities[action.meta.arg.conversationId]?.currentFilesInfos
        const lastElement = current?.pop()
        if (lastElement && current) {
          const newLast: Conversation['currentFilesInfos'][0] = { ...lastElement, uploadStatus: 'ERROR' }
          conversationsEntityAdapter.updateOne(state, {
            id: action.meta.arg.conversationId,
            changes: {
              currentFilesInfos: [...current, newLast],
            },
          })
        }
      })
      .addCase(uploadFileInConversation.pending, (state, action) => {
        state.isUploadingFileInConversation = true
        conversationsEntityAdapter.updateOne(state, {
          id: action.meta.arg.conversationId,
          changes: {
            currentFilesInfos: [
              ...(state.entities[action.meta.arg.conversationId]?.currentFilesInfos ?? []),
              { title: action.meta.arg.fileName, uploadStatus: 'IN_PROGRESS' },
            ],
          },
        })
      })
      .addCase(listUserConversationsForArticle.fulfilled, (state, action) => {
        state.conversationsForArticle = action.payload.map((conversation) => {
          return {
            ...conversation,
            currentFilesInfos: conversation.currentFilesInfos.map((fileInfos) => ({
              ...fileInfos,
              uploadStatus: 'UPLOADED',
            })),
          }
        })
      })
  },
})

export const {
  setActiveConversationId,
  updateConversationSnapshotByConversationId,
  setInitialQuestion,
  setConversationCurrentFileInfos,
  addConversation,
} = conversationsSlice.actions
export const conversationsSelector = conversationsEntityAdapter.getSelectors(
  (state: RootState) => state.entities.conversations,
)
// select all
export const selectConversationsForArticle = (state: RootState) => state.entities.conversations.conversationsForArticle

export const selectConversationForArticle = (id: LegalWatchArticle['id'] | undefined) => (state: RootState) => {
  return state.entities.conversations.conversationsForArticle.find((conversation) => conversation.articleId === id)
}
export const selectAllConversations = (state: RootState) => conversationsSelector.selectAll(state)
export const selectConversationsNoArticle = createSelector([selectAllConversations], (conversations) => {
  return conversations.filter((conversation) => conversation.type !== 'ARTICLE')
})

export const selectConversationIdByArticleId = (articleId: string | undefined) =>
  createSelector([selectConversationsForArticle], (conversations) => {
    const conversation = conversations.find((conversation) => {
      return conversation.articleId === articleId
    })
    return conversation?.id
  })

export const selectConversationById = (conversationId: Conversation['id']) => (state: RootState) =>
  conversationsSelector.selectById(state, conversationId)

export const selectIsUploadingFileInConversation = (state: RootState) =>
  state.entities.conversations.isUploadingFileInConversation
export const selectIsLoading = (state: RootState) => state.entities.conversations.isLoading
export const selectIsCreatingConversation = (state: RootState) => state.entities.conversations.isCreatingConversation
export const selectInitialQuestion = (state: RootState) => state.entities.conversations.initialQuestion
export const selectActiveConversationId = (state: RootState) => state.entities.conversations.activeConversationId
