import { useAppDispatch } from '@/store/hooks.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { uploadFileInConversation } from '@/store/conversations/useCases/uploadFileInConversation.ts'
import { ConversationType } from '@/types/conversation.ts'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { FileEntity } from '@/modules/document-analysis/domain/entities/FileEntity.ts'
import { analyzeDpaUseCase } from '@/modules/document-analysis/use-cases/analyzeDpaUseCase.ts'
import { setInitialQuestion } from '@/store/conversations/conversationsSlice.ts'
import { fetchExampleDpaUseCase } from '@/modules/document-analysis/use-cases/fetchExampleDpaUseCase.ts'
import { setAnalyseStatusToEmpty } from '@/modules/document-analysis/documentAnalysisSlice.ts'
import { DpaExamples } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'

export const useHomeHook = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const getConversationType = (files: File[] | undefined) => {
    if (files?.length === 2) {
      return 'FILE_COMPARISON'
    }
    return files?.length === 1 ? 'SINGLE_FILE' : 'MESSAGE_ONLY'
  }

  const process = async (question: string, files?: File[]) => {
    const type: ConversationType = getConversationType(files)
    try {
      const conversation = await dispatch(createConversation({ type })).unwrap()
      if (files?.length) {
        await uploadFilesToConversation(conversation.id, files)
      }
      dispatch(setInitialQuestion(question))
      if (isMounted.current) {
        navigate(`conversations/${conversation.id}`, { replace: true })
      }
    } catch (error) {}
  }

  const processDpa = async (files: FileEntity[]) => {
    try {
      const response = await dispatch(analyzeDpaUseCase({ file: files[0] })).unwrap()
      if (!response) return
      if (isMounted.current) {
        setTimeout(() => {
          navigate(`conversations/${response.id}`, { replace: true })
          dispatch(setAnalyseStatusToEmpty())
        }, 2000)
      }
    } catch (error) {}
  }

  const processExampleDpa = async ({ example }: { example: DpaExamples }) => {
    try {
      const response = await dispatch(fetchExampleDpaUseCase({ example })).unwrap()
      if (!response) return
      if (isMounted.current) {
        setTimeout(() => {
          navigate(`conversations/${response.id}`, { replace: true })
          dispatch(setAnalyseStatusToEmpty())
        }, 2000)
      }
    } catch (error) {}
  }

  const uploadFilesToConversation = async (conversationId: string, files: File[]) => {
    const uploadPromises = files.map((file) =>
      dispatch(
        uploadFileInConversation({
          conversationId,
          file,
          fileName: file.name,
        }),
      ),
    )

    await Promise.all(uploadPromises)
  }

  return {
    process,
    processDpa,
    processExampleDpa,
  }
}
