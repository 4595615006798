import { TheIconArrowSubmission } from '@/components/icons/TheIconArrowSubmission.tsx'

interface Props {
  handleSend: () => void
  userInputLength: number
}
export function SubmitButton({ handleSend, userInputLength }: Readonly<Props>) {
  return (
    <div className={'flex h-[40px] gap-4'}>
      <TheIconArrowSubmission onClick={handleSend} disabled={!userInputLength} />
    </div>
  )
}
