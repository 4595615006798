import { useMessagesListHook } from '@/components/hooks/use-MessagesList.hook.tsx'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectIsStreaming } from '@/store/messages/messagesSlice.ts'
import { useAskQuestion } from '@/utils/useAskQuestion.ts'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'
import { useConversationPageHook } from './use-conversation-page.hook.tsx'
import { RightDrawer } from '@/pages/conversations/[conversationId]/RightDrawer.tsx'
import { selectIsSourceListDrawerDisplayed, setSourceListDrawerDisplayed } from '@/store/drawers/drawersSlice.ts'
import { TheSourcesList } from '@/components/TheSourcesList.tsx'
import { BottomDrawer } from '@/pages/conversations/[conversationId]/BottomDrawer.tsx'
import { useDetectScreenSize } from '@/utils/useDetectScreenSize.ts'
import { TheConversationMessageInputMobile } from '@/components/TheConversationMessageInputMobile.tsx'
import { MobileSourceFilterDrawer } from '@/components/MobileSourceFilterDrawer.tsx'
import { ConversationList } from '@/components/ConversationList.tsx'
import { useEffect, useRef, useState } from 'react'
import { TheInputMessageDesktop } from '@/components/TheInputMessageDesktop.tsx'
import { useParams } from 'react-router-dom'
import { TheScrollToBottomButton } from '@/components/TheScrollToBottomButton.tsx'
import { useFetchMessages } from '@/components/hooks/useFetchMessages.tsx'
import { useCreateMessageWithInitialQuestion } from '@/components/hooks/useCreateMessageWithInitialQuestion.tsx'

export function ConversationPage() {
  // HOOKS
  const dispatch = useAppDispatch()
  const { conversationId } = useParams<{ conversationId: string }>()
  const { userInput, handleUpload, handleStopFileContext, handleUserInput, handleDisplayFilterMobile, resetUserInput } =
    useConversationPageHook()
  const { askQuestion } = useAskQuestion()
  const isMobile = useDetectScreenSize()
  const { sourceCollectionIds } = useSourceCollections()
  const isSourceListDrawerDisplayed = useAppSelector(selectIsSourceListDrawerDisplayed)
  const { messages, conversation } = useMessagesListHook()

  // GLOBAL STATE
  const isStreaming = useAppSelector(selectIsStreaming)

  // LOCAL STATE
  const [inputHeight, setInputHeight] = useState(0)
  const [showScrollButton, setShowScrollButton] = useState(false)

  // REFS
  const inputRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  // USE EFFECTS
  useEffect(() => {
    const updateHeight = () => {
      if (inputRef.current) {
        const computedStyle = getComputedStyle(inputRef.current)

        const height = inputRef.current.offsetHeight
        const marginTop = parseFloat(computedStyle.marginTop)
        const marginBottom = parseFloat(computedStyle.marginBottom)

        const totalHeight = height + marginTop + marginBottom
        setInputHeight(totalHeight)
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  }, [inputRef.current?.offsetHeight])

  useCreateMessageWithInitialQuestion({ conversation, sourceCollectionIds })
  useFetchMessages(conversationId)

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 100
        setShowScrollButton(!isNearBottom)
      }
    }

    const container = containerRef.current
    container?.addEventListener('scroll', handleScroll)

    return () => container?.removeEventListener('scroll', handleScroll)
  }, [])

  const handleSend = async () => {
    resetUserInput()
    await askQuestion(userInput, conversation, sourceCollectionIds)
  }
  const handleKeyDown = async (e: { key: string; shiftKey: boolean }) => {
    if (e.key === 'Enter' && !e.shiftKey && userInput.trim() !== '') {
      await handleSend()
    }
  }
  const scrollToBottom = () => {
    containerRef.current?.scrollTo({ top: containerRef.current.scrollHeight, behavior: 'smooth' })
  }

  return (
    <div
      style={{ height: `calc(100vh - ${inputHeight}px)` }}
      className={`grid flex-1 transition-all duration-300 ease-in-out sm:h-full 2xl:mx-auto 2xl:max-w-1400 ${
        isSourceListDrawerDisplayed && !isMobile ? 'grid-cols-[1fr,20vw]' : 'grid-cols-[1fr,0px]'
      }`}
    >
      <ConversationList containerRef={containerRef} inputHeight={inputHeight}>
        <div className="relative flex w-full justify-center">
          <div
            ref={inputRef}
            className="fixed bottom-0 flex w-full max-w-[800px] flex-col items-center gap-4 pb-6 sm:w-[calc(100%-240px)]"
          >
            {isMobile ? (
              <div className={'flex w-full'}>
                <TheConversationMessageInputMobile
                  messages={messages}
                  currentConversation={conversation}
                  isQuestionLoading={isStreaming}
                  upLoadFile={handleUpload}
                  userInput={userInput}
                  onInput={handleUserInput}
                  handleSend={handleSend}
                  handleDisplayFilterMobile={handleDisplayFilterMobile}
                  handleStopFileContext={handleStopFileContext}
                />
              </div>
            ) : (
              <div className={'w-full md:flex'}>
                <TheInputMessageDesktop
                  currentConversation={conversation}
                  handleSend={handleSend}
                  isQuestionLoading={isStreaming}
                  onInput={handleUserInput}
                  userInput={userInput}
                  handleKeyDown={handleKeyDown}
                />
              </div>
            )}
            <TheScrollToBottomButton onClick={scrollToBottom} visible={showScrollButton} />
          </div>
        </div>
      </ConversationList>
      <MobileSourceFilterDrawer />
      {isMobile ? (
        <BottomDrawer
          isOpen={isSourceListDrawerDisplayed}
          onClose={(value) => {
            dispatch(setSourceListDrawerDisplayed(value))
          }}
        >
          <TheSourcesList />
        </BottomDrawer>
      ) : (
        <RightDrawer
          isOpen={isSourceListDrawerDisplayed}
          onClose={(value) => {
            dispatch(setSourceListDrawerDisplayed(value))
          }}
        >
          <TheSourcesList />
        </RightDrawer>
      )}
    </div>
  )
}
