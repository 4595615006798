import ConversationShareMenu from '@/components/TheConversationTypesList/ConversationShareMenu.tsx'
import { Conversation } from '@/types/conversation.ts'
import { BackButtonContainer } from '@/components/BackButtonContainer.tsx'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

interface Props {
  messages: MessageEntity[]
  conversationId: string
  conversation: Conversation
}
export function ConversationOptionsBar({ messages, conversationId, conversation }: Readonly<Props>) {
  return (
    <div className="flex items-center justify-between">
      <BackButtonContainer path={ROUTE_PATHS.home} label={'Olympe'} />
      {!!messages.length && <ConversationShareMenu id={conversationId} snapshotId={conversation?.snapshotId} />}
    </div>
  )
}
