import { useEffect, useState } from 'react'

export function useDetectScreenSize() {
  const [isMobile, setIsMobile] = useState(false)
  const mobileMaxWith = 767

  useEffect(() => {
    detectScreenSize()
    window.addEventListener('resize', detectScreenSize)
    return () => window.removeEventListener('resize', detectScreenSize)
  }, [])

  const detectScreenSize = () => {
    setIsMobile(window.innerWidth <= mobileMaxWith)
  }

  return isMobile
}
