import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function MagicIcon(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 141.566 112.617">
      <path
        d="m100.84 55.204-36.435 63.302"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="translate(-33.56 -55.204)"
      />
      <path
        d="m96.056 67.717 34.595 45.636"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(1 0 0 1.04651 -33.928 -74.915)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(.39276 0 0 1.14238 44.488 -18.031)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(0 .29957 -1.2481 0 162.355 31.98)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(.14053 0 0 .38345 8.243 61.704)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(0 .10056 -.44656 0 50.416 78.49)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(.13092 0 0 .32753 109.968 62.677)"
      />
      <path
        d="M89.063 113.721 40.851 65.51l48.212-48.212 48.212 48.212Z"
        style={{
          strokeWidth: 0.264583,
        }}
        transform="matrix(0 .0859 -.41603 0 148.394 76.087)"
      />
    </svg>
  )
}
