import { Message } from '@/types/message.ts'
import { updateMessageFeedback } from '@/store/messages/useCases/updateMessageFeedback.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { Conversation } from '@/types/conversation.ts'

export const useMessageFeedBack = () => {
  const dispatch = useAppDispatch()

  const updateFeedback = (
    feedbackFromIcon: Message['feedback'],
    conversationId?: Conversation['id'],
    messageId?: Message['id'],
  ) => {
    if (!conversationId) return
    if (!messageId) return
    dispatch(updateMessageFeedback({ conversationId, messageId, feedback: feedbackFromIcon }))
  }

  const handleThumbDownClick = (
    feedbackFromIcon: 'THUMBS_UP' | 'THUMBS_DOWN',
    conversationId: Conversation['id'],
    messageId: Message['id'],
  ) => {
    if (feedbackFromIcon === 'THUMBS_DOWN') return () => updateFeedback('NULL')
    updateFeedback('THUMBS_DOWN', conversationId, messageId)
  }
  const handleThumbDownUpClick = (
    feedbackFromIcon: 'THUMBS_UP' | 'THUMBS_DOWN',
    conversationId: Conversation['id'],
    messageId: Message['id'],
  ) => {
    if (feedbackFromIcon === 'THUMBS_UP') return () => updateFeedback('NULL')
    updateFeedback('THUMBS_UP', conversationId, messageId)
  }

  return { handleThumbDownUpClick, handleThumbDownClick }
}
