import { ADDITIONAL_WAIT_TIME } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { useEffect, useState } from 'react'
import { selectUser } from '../../store/auth/authSlice.ts'
import { useAppSelector } from '../../store/hooks.ts'
import { useNavigate } from 'react-router-dom'
import { LOCAL_STORAGE_REDIRECTION_KEY, LOCAL_STORAGE_SLUG_KEY, ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

export function useGreetingUserHook() {
  const [name, setName] = useState('')
  const currentUser = useAppSelector(selectUser)
  const navigate = useNavigate()
  const redirectionValue = localStorage.getItem(LOCAL_STORAGE_REDIRECTION_KEY)
  const slugValue = localStorage.getItem(LOCAL_STORAGE_SLUG_KEY)

  const removeLocalStorageItems = (items: string[]) => {
    items.forEach((item) => {
      localStorage.removeItem(item)
    })
  }

  const navigateTo = ({ to, items }: { to: string; items: string[] }) => {
    navigate(to)
    removeLocalStorageItems(items)
  }

  async function redirect() {
    if (slugValue) {
      navigateTo({
        to: `${ROUTE_PATHS.legalWatch}/s/${slugValue}`,
        items: [LOCAL_STORAGE_SLUG_KEY, LOCAL_STORAGE_REDIRECTION_KEY],
      })
    } else if (redirectionValue) {
      navigateTo({ to: ROUTE_PATHS.legalWatch, items: [LOCAL_STORAGE_REDIRECTION_KEY] })
    } else {
      navigate(ROUTE_PATHS.home)
    }
  }

  // Set the name whenever the user changes
  useEffect(() => {
    setName(currentUser?.firstname ?? '')
  }, [currentUser])

  // Handle redirection
  useEffect(() => {
    const timeout = setTimeout(redirect, ADDITIONAL_WAIT_TIME)
    return () => {
      clearTimeout(timeout)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const capitalizeName = (): string => {
    if (!name) return ''
    return name[0].toUpperCase() + name.slice(1)
  }

  return { capitalizedName: capitalizeName() }
}
