import { Link } from 'react-router-dom'
import { TheIconMenuBurger } from './icons/TheIconMenuBurger.tsx'
import { TheIconNew } from './icons/TheIconNew.tsx'
import { TheIconOlympeLogoWithoutText } from './icons/TheIconOlympeLogoWithoutText.tsx'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

type Props = {
  onHandleSidebar: () => void
}

export function TheHeaderMobile({ onHandleSidebar }: Readonly<Props>) {
  const handleSidebar = () => {
    onHandleSidebar()
  }

  return (
    <header className="flex h-14 w-screen items-center justify-between bg-bright px-5 py-2 shadow sm:hidden">
      <TheIconMenuBurger onClick={handleSidebar} />
      <TheIconOlympeLogoWithoutText className={'h-12'} />
      <Link to={ROUTE_PATHS.home}>
        <TheIconNew />
      </Link>
    </header>
  )
}
