import { useLoginHook } from './use-login.hook.ts'
import { LoginPageRightPart } from '@/pages/login/LoginPageRightPart.tsx'
import { LoginPageLeftPart } from './LoginPageLeftPart.tsx'
import { useFetchUser } from '@/components/hooks/use-FetchUser.hook.tsx'

export function LoginPage() {
  const { enableLoginFromParentWindow } = useLoginHook()

  enableLoginFromParentWindow()
  useFetchUser()

  return (
    <div className={'relative flex h-screen p-7 2xl:mx-auto 2xl:max-w-1400'}>
      <LoginPageLeftPart />
      <LoginPageRightPart />
    </div>
  )
}
