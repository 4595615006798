import { Button } from '@/components/ui/button.tsx'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { Link } from 'react-router-dom'
import React, { useRef, useState } from 'react'
import { Paperclip, X } from 'lucide-react'
import { Textarea } from '@/components/ui/textarea.tsx'
import { Card, CardContent, CardHeader, CardTitle } from './ui/card'
import { ALLOWED_FILE_TYPES_AS_STRING } from '@/utils/FileTypes.ts'
import { BsArrowUpCircleFill } from 'react-icons/bs'
import { cn } from '@/lib/utils.ts'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'
import { useDetectScreenSize } from '@/utils/useDetectScreenSize.ts'
import { useConversationPageHook } from '@/pages/conversations/[conversationId]/use-conversation-page.hook.tsx'
import { HomepagePopover } from '@/components/HomepagePopover.tsx'
import { useToaster } from '@/utils/useToaster.ts'
import TheConversationAILoader from '@/components/TheConversationAILoader.tsx'

export interface FileState {
  file: File | null
  loading: boolean
  uploaded: boolean
}

export interface HomePageProps {
  process: (question: string, file?: File[]) => void
  isLoading: boolean
}

export function QuestionForm({ process, isLoading }: Readonly<HomePageProps>) {
  const [question, setQuestion] = useState('')
  const [questionFiles, setQuestionFiles] = useState<FileState[]>([])
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { groups, sourceCollectionIds, handleSelectedSourceCollectionsChange } = useSourceCollections()
  const isMobile = useDetectScreenSize()
  const [isOpen, setIsOpen] = useState(false)
  const { handleDisplayFilterMobile } = useConversationPageHook()
  const { showToast } = useToaster()
  const MAX_FILE_SIZE = 20 * 1024 * 1024

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!file) return

    const isFileSizeExceeded = file.size > MAX_FILE_SIZE
    const isFileLimitReached = questionFiles.length >= 2

    if (isFileSizeExceeded) {
      showToast({
        toaster: {
          toasterMessage: 'Le fichier dépasse la limite de taille de 20 Mo.',
          toasterType: 'identified',
        },
      })
      return
    }

    if (isFileLimitReached) return

    const newFileState: FileState = { file, loading: true, uploaded: false }

    setQuestionFiles((prevFiles) => [...prevFiles, newFileState])

    try {
      setTimeout(() => {
        setQuestionFiles((prevFiles) =>
          prevFiles.map((f) => (f.file === file ? { ...f, loading: false, uploaded: true } : f)),
        )
      }, 5000)
    } catch (error) {
      setQuestionFiles((prevFiles) => prevFiles.filter((f) => f.file !== file))
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && question.trim() !== '' && !isMobile) {
      e.preventDefault()
      await handleQuestionSubmit()
    }
  }

  const handleQuestionSubmit = async () => {
    if (question.trim()) {
      try {
        process(
          question,
          questionFiles.map((f) => f.file!),
        )
        setQuestion('')
        setQuestionFiles([])
      } catch (error) {}
    }
  }

  const removeFile = (index: number) => {
    setQuestionFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
  }

  return (
    <Card className="flex flex-col">
      <CardHeader>
        <CardTitle>Poser une question</CardTitle>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="mb-4 text-sm text-muted-foreground">
          L&apos;assistant Olympe améliore votre expertise RGPD et protection des données. Posez-lui une question ou
          demandez-lui de vous aider à analyser les documents que vous attachez. Il ne remplace pas les conseils
          d&apos;un avocat.{' '}
          <Link to={ROUTE_PATHS.promptTutorial} className="text-electra hover:text-mintGreen hover:underline">
            (voir des conseils de rédaction)
          </Link>
        </p>
        <div className={'mb-4 flex w-full justify-end'}>
          <HomepagePopover
            open={isOpen}
            onOpenChange={() => !isMobile && setIsOpen(!isOpen)}
            onClick={() => (isMobile ? handleDisplayFilterMobile(true) : undefined)}
            groups={groups}
            selectedItems={sourceCollectionIds}
            onSelectedItemsChange={handleSelectedSourceCollectionsChange}
          />
        </div>

        <div className="relative">
          <Textarea
            placeholder="Posez votre question ici..."
            value={question}
            onKeyDown={handleKeyDown}
            onChange={(e) => setQuestion(e.target.value)}
            className="min-h-[100px] pr-12"
          />
          <div className={'absolute bottom-2 right-2 flex flex-col items-center justify-center gap-1 md:flex-row'}>
            {isLoading ? (
              <TheConversationAILoader />
            ) : (
              <Button
                size={'icon'}
                variant={'ghost'}
                onClick={question.trim() ? handleQuestionSubmit : undefined}
                className={cn(
                  'p-0 transition-colors duration-200',
                  question.trim() ? 'cursor-pointer' : 'cursor-default',
                )}
              >
                <BsArrowUpCircleFill className={cn('h-8 w-8  fill-flintRock', question.trim() && ' fill-mintGreen')} />
              </Button>
            )}
          </div>
          <input
            type="file"
            accept={ALLOWED_FILE_TYPES_AS_STRING}
            ref={fileInputRef}
            onChange={handleFileSelect}
            className="hidden"
          />
        </div>
        {questionFiles.length > 0 && (
          <div className="mt-2 space-y-2">
            {questionFiles.map((fileState, index) => (
              <div key={index} className="flex items-center justify-between rounded bg-gray-100 p-2">
                <p className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-sm text-muted-foreground">
                  {fileState.file?.name}
                </p>
                <Button variant="ghost" size="sm" onClick={() => removeFile(index)}>
                  <X className="h-4 w-4" />
                </Button>
              </div>
            ))}
          </div>
        )}
        {questionFiles.length < 2 && (
          <Button onClick={handleIconClick} className="mt-2 w-full" variant="outline">
            <Paperclip className="mr-2 h-4 w-4" />
            <p>Ajouter un document</p>
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
