import TextareaAutosize from 'react-textarea-autosize'
import { SubmitButton } from '@/components/SubmitButton.tsx'
import { cn } from '@/lib/utils.ts'
import { ChangeEvent } from 'react'
import { ConversationType } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { Conversation } from '@/types/conversation.ts'

export type TheConversationMessageInputProps = {
  isQuestionLoading: boolean
  currentConversation?: Conversation
  userInput: string
  onInput: (event: ChangeEvent<HTMLTextAreaElement>) => void
  handleSend: () => Promise<void>
  handleKeyDown?: (e: { key: string; shiftKey: boolean }) => void
}

export function TheInputMessageDesktop({
  isQuestionLoading,
  userInput,
  onInput,
  handleKeyDown,
  handleSend,
  currentConversation,
}: Readonly<TheConversationMessageInputProps>) {
  return (
    <div
      className={cn(
        `group flex justify-between rounded-lg bg-bright py-4 pl-6 pr-5 shadow-custom sm:w-full sm:gap-2.5`,
        currentConversation?.type === ConversationType.ANALYZE && 'hidden',
      )}
    >
      <TextareaAutosize
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading || currentConversation?.type === ConversationType.ANALYZE}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className={cn(
          'my-4 max-h-[200px] w-full resize-none rounded-lg bg-bright text-base leading-5 outline-none sm:my-0 sm:py-2.5',
        )}
      />
      <div className={cn('self-end')}>
        <SubmitButton handleSend={handleSend} userInputLength={userInput.length} />
      </div>
    </div>
  )
}
