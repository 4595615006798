import { Tabs } from '@radix-ui/react-tabs'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from './ui/card'
import { TabsContent, TabsList, TabsTrigger } from './ui/tabs'
import { Button } from './ui/button'
import { Badge } from './ui/badge'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { ALLOWED_FILE_TYPES_AS_STRING } from '@/utils/FileTypes.ts'
import { useToaster } from '@/utils/useToaster.ts'
import { FileEntity } from '@/modules/document-analysis/domain/entities/FileEntity.ts'
import { OlympeError } from '@/modules/shared/OlympeError.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import {
  AnalyseStatus,
  selectAnalyseStatus,
  selectExampleFileName,
  setAnalyseStatusToEmpty,
} from '@/modules/document-analysis/documentAnalysisSlice.ts'
import DpaFormButton from '@/components/DpaFormButton.tsx'
import DpaFormButtonSoon from '@/components/DpaFormButtonSoon.tsx'
import DpaFormButtonProgress from '@/components/DpaFormButtonProgress.tsx'
import DpaFormButtonDefault from '@/components/DpaFormButtonDefault.tsx'

export interface FileStateDpa {
  file: FileEntity | null
  loading: boolean
  uploaded: boolean
}

export enum AnalysisType {
  DPA = 'DPA',
  PrivacyPolicy = 'Privacy Policy',
}

export enum DpaExamples {
  DPA_AWS_EXAMPLE = 'dpa_aws',
  DPA_OVH_EXAMPLE = 'dpa_ovh',
  DPA_STRIPE_EXAMPLE = 'dpa_stripe',
}

export interface HomePageProps {
  processDpa: (file: FileEntity[]) => void
  getAnalysisDocumentExample: ({ example }: { example: DpaExamples }) => void
}

export function DpaOrPrivacyPolicyAnalyzer({ processDpa, getAnalysisDocumentExample }: Readonly<HomePageProps>) {
  const [files, setFiles] = useState<FileStateDpa[]>([])
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { showToast } = useToaster()
  const analyseStatus = useAppSelector(selectAnalyseStatus)
  const exampleFileName = useAppSelector(selectExampleFileName)
  const [analysisType, setAnalysisType] = useState<AnalysisType>(AnalysisType.DPA)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setTimeout(() => {
      if (analyseStatus === 'COMPLETE') dispatch(setAnalyseStatusToEmpty())
    }, 2000)
  }, [dispatch, analyseStatus])

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0]
    if (!file) return

    const fileEntity = FileEntity.create(file)

    if (fileEntity instanceof OlympeError) {
      showToast({
        toaster: {
          toasterMessage: fileEntity.error,
          toasterType: 'identified',
        },
      })
      return
    }

    const newFileState: FileStateDpa = { file: fileEntity, loading: true, uploaded: false }

    setFiles((prevFiles) => [...prevFiles, newFileState])

    try {
      setTimeout(() => {
        setFiles((prevFile) =>
          prevFile.map((f) => (f.file === fileEntity ? { ...f, loading: false, uploaded: true } : f)),
        )
      }, 5000)
    } catch (error) {
      setFiles((prevFiles) => prevFiles.filter((f) => f.file !== fileEntity))
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }

    processDpa([fileEntity])
  }

  const handleIconClick = (inputRef: RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Card className="flex flex-col">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          Obtenir une synthèse
          <Badge variant="secondary" className="bg-emerald-100 text-emerald-700">
            Nouveau
          </Badge>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="mb-4 text-sm text-muted-foreground">
          Bénéficiez de nos analyses automatisées des DPA et Privacy Policy pour les passer en revue plus rapidement.
        </p>
        <Tabs
          defaultValue="DPA"
          className="w-full"
          onValueChange={(value) => setAnalysisType(value as AnalysisType.DPA | AnalysisType.PrivacyPolicy)}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="DPA">DPA</TabsTrigger>
            <TabsTrigger value="Privacy Policy">Privacy Policy</TabsTrigger>
          </TabsList>
          <TabsContent value="DPA">
            <p className="mb-2 mt-2 text-sm font-medium">Exemples :</p>
            <div className="flex flex-col items-start gap-1 space-y-1">
              <Button
                onClick={() => getAnalysisDocumentExample({ example: DpaExamples.DPA_AWS_EXAMPLE })}
                variant={'minimal'}
                size={'minimal'}
                asChild
                data-testid="dpa-aws-example"
              >
                <span className="block text-sm text-primary hover:underline">DPA Amazon AWS</span>
              </Button>
              <Button
                onClick={() => getAnalysisDocumentExample({ example: DpaExamples.DPA_OVH_EXAMPLE })}
                variant={'minimal'}
                size={'minimal'}
                asChild
              >
                <span className="block text-sm text-primary hover:underline">DPA OVHCloud</span>
              </Button>
              <Button
                onClick={() => getAnalysisDocumentExample({ example: DpaExamples.DPA_STRIPE_EXAMPLE })}
                variant={'minimal'}
                size={'minimal'}
                asChild
              >
                <span className="block text-sm text-primary hover:underline">DPA Stripe</span>
              </Button>
            </div>
          </TabsContent>
          <input
            type="file"
            accept={ALLOWED_FILE_TYPES_AS_STRING}
            ref={fileInputRef}
            onChange={handleFileSelect}
            className="hidden"
            data-testid="file-input"
          />
        </Tabs>
      </CardContent>
      <CardFooter className="mt-auto">
        {analysisType === AnalysisType.DPA ? (
          <DpaFormButton disabled={analyseStatus !== AnalyseStatus.EMPTY} onClick={() => handleIconClick(fileInputRef)}>
            <div className="flex w-full flex-col items-center">
              <div className="flex h-full w-full justify-center">
                {analyseStatus !== AnalyseStatus.EMPTY ? (
                  <DpaFormButtonProgress
                    fileStateDpas={files}
                    exampleFileName={exampleFileName}
                    analyseStatus={analyseStatus}
                  />
                ) : (
                  <DpaFormButtonDefault />
                )}
              </div>
            </div>
          </DpaFormButton>
        ) : (
          <DpaFormButton disabled={true} onClick={() => handleIconClick(fileInputRef)}>
            <DpaFormButtonSoon />
          </DpaFormButton>
        )}
      </CardFooter>
    </Card>
  )
}
