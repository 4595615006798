import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<SVGSVGElement> {
  onClick?: () => void
}

export function TheIconRefresh({ onClick, ...props }: Readonly<Props>) {
  return (
    <svg
      onClick={onClick}
      {...props}
      width="32"
      height="30"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.54661 17.7668C9.94575 18.8311 12.8032 18.7733 15.2502 17.3606C19.3157 15.0134 20.7086 9.81483 18.3614 5.74934L18.1114 5.31633M3.63851 14.2495C1.2913 10.184 2.68424 4.98546 6.74972 2.63825C9.19671 1.22548 12.0542 1.16767 14.4533 2.23204M1.49341 14.3331L4.22546 15.0652L4.95751 12.3331M17.0426 7.66516L17.7747 4.93311L20.5067 5.66516"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
