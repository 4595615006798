import { JSX } from 'react/jsx-runtime'
import { SVGProps } from 'react'

export function MinimalisticArrowIconRight(props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) {
  return (
    <svg className={props.className} {...props} viewBox="5 -2 18 24" fill="none" width="24" height="24">
      <g clipPath="url(#clip0_558_7078)">
        <path
          d="M19.9999 10.2119C19.8571 10.416 19.7407 10.6469 19.5667 10.8202C17.9955 12.3882 16.4078 13.9391 14.8406 15.5106C14.342 16.0104 13.8274 15.8864 13.5535 15.6057C13.2718 15.3163 13.1739 14.8017 13.6754 14.3175C14.7865 13.2449 15.8756 12.1495 16.9765 11.0662C17.0248 11.0189 17.1018 11.0013 17.1656 10.97C17.1427 10.9403 17.1203 10.911 17.0974 10.8812H16.8722C13.7923 10.8812 10.7124 10.8812 7.63247 10.8812C7.12857 10.8812 6.80693 10.6459 6.71531 10.2158C6.61444 9.74326 6.96678 9.27169 7.44777 9.23751C7.53793 9.23117 7.62906 9.23214 7.7197 9.23214C10.7675 9.23214 13.8147 9.23214 16.8625 9.23214C16.939 9.23214 17.0155 9.23214 17.1456 9.23214C17.0662 9.1472 17.0184 9.09301 16.9673 9.04224C15.8601 7.94678 14.7533 6.85083 13.6437 5.75781C13.4024 5.52007 13.2606 5.25353 13.3391 4.90399C13.4716 4.31477 14.1612 4.06287 14.6412 4.43095C14.708 4.48221 14.7694 4.54079 14.8293 4.59986C16.4356 6.1874 18.0428 7.77397 19.6437 9.36737C19.7855 9.50894 19.882 9.69591 19.9994 9.86238V10.2134L19.9999 10.2119Z"
          fill="#FDFDFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_558_7078">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
