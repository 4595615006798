import { Group, NestedCheckboxList } from '@/components/NestedCheckboxList.tsx'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.tsx'
import { Button } from '@/components/ui/button.tsx'
import { ChevronDown } from 'lucide-react'

interface Props {
  open: boolean
  onOpenChange: () => false | void
  onClick: () => void
  groups: Group[]
  selectedItems: string[]
  onSelectedItemsChange: (newSelectedSourceCollections: string[]) => void
}

export function HomepagePopover(props: Readonly<Props>) {
  return (
    <Popover open={props.open} onOpenChange={props.onOpenChange}>
      <PopoverTrigger className={'p-0'} asChild>
        <Button
          className={'group flex h-fit w-full gap-2 px-4 text-label xl:w-fit'}
          onClick={props.onClick}
          variant="outline"
        >
          Base documentaire
          <ChevronDown className={'w-4 stroke-label group-hover:stroke-primary'} />
        </Button>
      </PopoverTrigger>
      <PopoverContent side={'right'} className={'w-auto rounded-2xl p-0'}>
        <NestedCheckboxList
          title={'SOURCES'}
          className={
            'flex max-h-halfVh w-80 flex-col gap-3 overflow-y-auto rounded border border-solid border-bInput bg-bright px-2 py-3 shadow-softOutline'
          }
          groups={props.groups}
          selectedItems={props.selectedItems}
          onSelectedItemsChange={props.onSelectedItemsChange}
          isUserConnected={true}
        />
      </PopoverContent>
    </Popover>
  )
}
