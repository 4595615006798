import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore'
import { getTextById } from './useCases/getText'
import { getTextBySlug } from './useCases/getTextBySlug'
import { listTexts } from './useCases/listTexts'
import { LegalResource } from '@/types/legalResource.ts'

const textsEntityAdapter = createEntityAdapter<LegalResource>({})
export const textsSlice = createSlice({
  name: 'texts',
  initialState: textsEntityAdapter.getInitialState({}),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listTexts.fulfilled, (state, action) => {
        textsEntityAdapter.setAll(state, action.payload)
      })
      .addCase(getTextById.fulfilled, (state, action) => {
        textsEntityAdapter.upsertOne(state, action.payload)
      })
      .addCase(getTextBySlug.fulfilled, (state, action) => {
        textsEntityAdapter.upsertOne(state, action.payload)
      })
  },
})

export const textsSelector = textsEntityAdapter.getSelectors((state: RootState) => state.entities.texts)

export const selectAllTexts = (state: RootState) => textsSelector.selectAll(state)

export const selectFilteredTexts = (searchTerm: string) => (state: RootState) => {
  const allTexts = textsSelector.selectAll(state)
  if (!searchTerm) return allTexts
  return allTexts.filter(
    (text) =>
      text.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      text.excerpt.toLowerCase().includes(searchTerm.toLowerCase()),
  )
}
