import { Message } from '@/types/message.ts'
import { setSources } from '@/store/messages/messagesSlice.ts'
import { setSourceListDrawerDisplayed } from '@/store/drawers/drawersSlice.ts'
import { createAppAsyncThunk } from '@/store/createAppThunk.ts'

export const toggleSourcesWithList = createAppAsyncThunk(
  'messages/toggleSourcesWithList',
  async ({ sources, isDrawerDisplayed }: { sources: Message['sources']; isDrawerDisplayed: boolean }, { dispatch }) => {
    dispatch(setSources(sources))
    dispatch(setSourceListDrawerDisplayed(isDrawerDisplayed))
  },
)
