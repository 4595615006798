import { SourceReadingInfos } from '../types/source'
import { LiaExternalLinkSquareAltSolid } from 'react-icons/lia'

type Props = {
  source: SourceReadingInfos
  sourceNumber: number
  key?: number
}

export function TheSourcesListItem(props: Readonly<Props>) {
  const getSourceIcon = (url: string): string | undefined => {
    const patterns: { pattern: RegExp; icon: string }[] = [
      { pattern: /cnil\.fr/, icon: '/assets/favicons/cnil-favicon.ico' },
      { pattern: /legifrance\.gouv\.fr/, icon: '/assets/favicons/legifrance-favicon.ico' },
      { pattern: /cyber\.gouv\.fr/, icon: '/assets/favicons/cyber-gouv-favicon.svg' },
      { pattern: /edpb\.europa\.eu/, icon: '/assets/favicons/edpb-favicon.ico' },
      { pattern: /curia\.europa\.eu/, icon: '/assets/favicons/curia.gif' },
    ]

    const matchedPattern = patterns.find(({ pattern }) => pattern.test(url))
    return matchedPattern ? matchedPattern.icon : undefined
  }
  return (
    <a href={props.source.url} target="_blank" rel="noreferrer" className={'block w-full'}>
      <div className="flex gap-4 rounded-md border bg-bright p-4 hover:bg-mintGreen hover:bg-opacity-30 md:max-w-[23rem]">
        <div className={'flex w-[20px] justify-center pt-[4px]'}>
          {getSourceIcon(props.source.url) ? (
            <img
              src={getSourceIcon(props.source.url)}
              alt={`${new URL(props.source.url).hostname} icon`}
              className="h-auto max-h-[1rem] min-h-[1rem] min-w-[1rem] object-contain"
            />
          ) : (
            <LiaExternalLinkSquareAltSolid />
          )}
        </div>
        <div className="flex w-full flex-col overflow-y-auto">
          <p className="mb-1 text-base text-mintGreen">
            <span className={'text-base text-mintGreen'}>
              {new URL(props.source.url).hostname.toLowerCase().replace(/^[a-z]/, (c) => c.toUpperCase())}
            </span>{' '}
            {props.source.pageNumber !== undefined && `(PDF p.${props.source.pageNumber})`}{' '}
          </p>
          <p className={'break-words text-sm leading-4'}> {props.source.title}</p>
        </div>
      </div>
    </a>
  )
}
