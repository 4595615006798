import { HTMLAttributes } from 'react'

function TableMarkdown(props: HTMLAttributes<HTMLTableElement>) {
  return (
    <div className="shadowSoft my-6 overflow-hidden rounded-lg bg-white">
      <table role="presentation" {...props} className="w-full border-collapse border border-brightGray text-gray-700" />
    </div>
  )
}
export default TableMarkdown
