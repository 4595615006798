import { useEffect, useRef } from 'react'
import { Conversation } from '../types/conversation.ts'
import { TheIconThumbDown } from './TheIconThumbDown.tsx'
import { TheIconThumbUp } from './TheIconThumbUp.tsx'
import { BaseButton } from './atomics/BaseButton.tsx'
import { TheIconCalendar } from './icons/TheIconCalendar.tsx'
import { useMessageFeedBack } from '@/components/utils/useMessageFeedBack.ts'
import { openCalendlyModal } from '@/store/modal/modalsSlice.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { toggleSourcesWithList } from '@/store/drawers/useCases/ToggleSourcesWithList.ts'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'
import { MessageItemAI } from '@/components/MessageItemAI.tsx'
import { MessageItemUser } from '@/components/MessageItemUser.tsx'

type Props = { message: MessageEntity; conversation: Conversation }

const MESSAGE_CONTENT_LENGTH_THRESHOLD = 1500

export function MessageItemContainer(props: Readonly<Props>) {
  const dispatch = useAppDispatch()
  const calendlyCtaRef = useRef<HTMLDivElement>(null)
  const { handleThumbDownUpClick, handleThumbDownClick } = useMessageFeedBack()

  useEffect(() => {
    if (calendlyCtaRef.current && props.message.feedback && props.message.feedback === 'THUMBS_DOWN') {
      calendlyCtaRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [props.message.feedback])

  const toggleSourcesList = () => {
    if (!props.message.sources) return
    dispatch(
      toggleSourcesWithList({
        sources: props.message.sources,
        isDrawerDisplayed: true,
      }),
    )
  }

  if (!props.conversation) return null

  if (props.message.type === 'HUMAN') return <MessageItemUser message={props.message} />

  return (
    <MessageItemAI conversation={props.conversation} message={props.message} onClick={toggleSourcesList}>
      <>
        {props.conversation.type === 'MESSAGE_ONLY' &&
          props.message.content.length > MESSAGE_CONTENT_LENGTH_THRESHOLD && (
            <BaseButton
              className={'mt-4 w-full sm:w-fit'}
              label={`Voir les ${props.message.sources?.length} sources officielles`}
              onClick={toggleSourcesList}
              disabled={props.message.sources && props.message.sources?.length < 1}
            />
          )}
        <div className="mb-2 mt-3 flex items-center justify-center gap-[0.5rem] md:justify-start">
          <p className="text-center text-base text-label sm:text-left sm:text-sm">Satisfait de la réponse ?</p>
          <button
            onClick={() => handleThumbDownUpClick('THUMBS_DOWN', props.conversation.id, props.message.id)}
            className={'group'}
          >
            <TheIconThumbUp
              className={'group-hover:stroke-primary'}
              isSelected={props.message.feedback === 'THUMBS_UP'}
            />
          </button>
          <button
            onClick={() => handleThumbDownClick('THUMBS_UP', props.conversation.id, props.message.id)}
            className={'group'}
          >
            <TheIconThumbDown
              className={'group-hover:stroke-primary'}
              isSelected={props.message.feedback === 'THUMBS_DOWN'}
            />
          </button>
        </div>
        {props.message.feedback === 'THUMBS_DOWN' && (
          <div className={'flex flex-col gap-3 sm:pl-9'}>
            <p className={`mb-2 mt-5 text-left text-sm sm:text-left`}>
              Souhaitez-vous contacter nos spécialistes pour mieux répondre à votre question ?
            </p>
            <BaseButton
              onClick={() => dispatch(openCalendlyModal())}
              label="Prendre RDV"
              color="quaternary"
              icon={<TheIconCalendar />}
              className="w-1/2 self-center rounded-3xl border border-primary px-1.5 py-0.5 text-sm font-light hover:text-sm hover:font-light sm:self-start sm:rounded-md"
            />
          </div>
        )}
      </>
    </MessageItemAI>
  )
}
