import { TheIconArrowSubmission } from './icons/TheIconArrowSubmission.tsx'
import { ReactNode } from 'react'
import { TheIconFilterMobile } from './icons/TheIconFilterMobile.tsx'
import TextareaAutosize from 'react-textarea-autosize'
import { TheConversationMessageInputMobileProps } from '@/components/TheInputSingleFileTypeMobile.tsx'
import { cn } from '@/lib/utils.ts'
import TheConversationAILoader from '@/components/TheConversationAILoader.tsx'

export function TheInputMessageOnlyTypeMobile({
  isQuestionLoading,
  userInput,
  onInput,
  handleKeyDown,
  handleSend,
  handleDisplayFilterMobile,
  messages,
}: Readonly<TheConversationMessageInputMobileProps>) {
  const renderSubmitOrLoadingIcon = (): ReactNode => {
    return isQuestionLoading ? (
      <TheConversationAILoader />
    ) : (
      <div className={cn(`flex w-1/4 items-end gap-4 bg-snowMist`, messages?.length && 'justify-center')}>
        {!messages?.length && (
          <TheIconFilterMobile onClick={() => handleDisplayFilterMobile(true)} className={'h-7 w-7'} />
        )}{' '}
        <TheIconArrowSubmission onClick={handleSend} disabled={!userInput.length} />
      </div>
    )
  }
  return (
    <div className={`flex w-full justify-between gap-2 bg-bright`}>
      <TextareaAutosize
        placeholder="Vous pouvez poser votre question"
        value={userInput}
        disabled={isQuestionLoading}
        onInput={onInput}
        onKeyDown={handleKeyDown}
        className="max-h-[200px] w-full resize-none rounded border border-solid border-fadedSilver bg-bright p-3 text-base leading-[18px] shadow-subtleElevation outline-none sm:my-0 sm:py-2.5"
      />
      {renderSubmitOrLoadingIcon()}
    </div>
  )
}
