import React, { ChangeEvent, FormEvent } from 'react'
import { useAppSelector } from '../store/hooks.ts'
import {
  TheConversationMessageInputMobileProps,
  TheInputSingleFileTypeMobile,
} from './TheInputSingleFileTypeMobile.tsx'
import { TheInputMessageOnlyTypeMobile } from './TheInputMessageOnlyTypeMobile.tsx'
import { TheInputFileComparisonTypeMobile } from './TheInputFileComparisonTypeMobile.tsx'
import { selectAllMessages } from '../store/messages/messagesSlice.ts'
import { ConversationType } from '@/modules/conversation/domain/entities/ConversationEntity.ts'

export function TheConversationMessageInputMobile({
  isQuestionLoading,
  userInput,
  upLoadFile,
  handleStopFileContext,
  onInput,
  handleSend,
  currentConversation,
  handleDisplayFilterMobile,
}: Readonly<TheConversationMessageInputMobileProps>) {
  const messages = useAppSelector(selectAllMessages)

  const resize = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement
    target.style.height = 'auto'
    target.style.height = `${target.scrollHeight}px`
    onInput(event)
  }

  const handleInputFileOpening = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const handleFileInputChange = (event: FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files?.length) return
    upLoadFile(event.currentTarget.files[0])
  }

  const handleStopUploadContext = () => {
    handleStopFileContext()
  }

  const renderMessageInput = () => {
    if (currentConversation?.type === 'MESSAGE_ONLY' || messages.length) {
      return (
        <TheInputMessageOnlyTypeMobile
          handleDisplayFilterMobile={handleDisplayFilterMobile}
          messages={messages}
          handleSend={handleSend}
          handleStopFileContext={handleStopUploadContext}
          isQuestionLoading={isQuestionLoading}
          handleInputFileOpening={handleInputFileOpening}
          onInput={onInput}
          upLoadFile={upLoadFile}
          currentConversation={currentConversation}
          userInput={userInput}
          resize={resize}
          handleFileInputChange={handleFileInputChange}
        />
      )
    } else if (currentConversation?.type === 'FILE_COMPARISON') {
      return (
        <TheInputFileComparisonTypeMobile
          handleDisplayFilterMobile={handleDisplayFilterMobile}
          handleSend={handleSend}
          handleStopFileContext={handleStopFileContext}
          isQuestionLoading={isQuestionLoading}
          onInput={onInput}
          upLoadFile={upLoadFile}
          currentConversation={currentConversation}
          userInput={userInput}
          handleInputFileOpening={handleInputFileOpening}
          handleFileInputChange={handleFileInputChange}
        />
      )
    } else {
      return (
        <TheInputSingleFileTypeMobile
          handleDisplayFilterMobile={handleDisplayFilterMobile}
          handleSend={handleSend}
          handleStopFileContext={handleStopUploadContext}
          isQuestionLoading={isQuestionLoading}
          handleInputFileOpening={handleInputFileOpening}
          onInput={onInput}
          upLoadFile={upLoadFile}
          currentConversation={currentConversation}
          userInput={userInput}
          resize={resize}
          handleFileInputChange={handleFileInputChange}
        />
      )
    }
  }

  if (currentConversation?.type === ConversationType.ANALYZE) return null

  return <>{renderMessageInput()}</>
}
