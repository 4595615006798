import { TheIconOlympeLogoWithText } from '../../components/icons/TheIconOlympeLogoWithText.tsx'
import { useEffect } from 'react'
import { SUBSCRIPTION_CONFIRMATION_WAIT_TIME } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

export function ConfirmSubscription() {
  const navigate = useNavigate()

  useEffect(() => {
    async function redirect() {
      navigate(ROUTE_PATHS.home)
    }
    const timeout = setTimeout(redirect, SUBSCRIPTION_CONFIRMATION_WAIT_TIME)
    return () => {
      clearTimeout(timeout)
    }
  }, [navigate])

  return (
    <section className={'flex h-full flex-col items-center justify-center gap-5 p-4'}>
      <TheIconOlympeLogoWithText className={'h-[3.3125rem] w-[8.75rem] fill-primary'} />
      <main className={'space-y-10'}>
        <div className={'flex flex-col items-center justify-center gap-3'}>
          <h1 className={'text-center text-[2.125rem] font-semibold leading-[2.5rem] text-primary'}>
            Votre abonnement a bien été pris en compte.
          </h1>
          <div className={'text-4xl font-semibold'}>✅</div>
        </div>
        <footer className={'text-center text-2xl font-normal'}>
          Vous allez automatiquement être redirigé vers Olympe.
        </footer>
      </main>
    </section>
  )
}
