import { useNavigate } from 'react-router-dom'
import { RoutePathsItem } from '@/ROUTE_PATHS.ts'

export const useBackButtonContainerHook = () => {
  const navigate = useNavigate()
  const navigateBack = (path: RoutePathsItem) => {
    navigate(path, { replace: true })
  }
  return {
    navigateBack,
  }
}
