import { CreateConversationResponseData } from '@/types/conversation.ts'
import { createAppAsyncThunk } from '../../createAppThunk.ts'
import { CreateConversationPayload } from '@/gateways/interface/conversation.gateway.ts'

export const createConversation = createAppAsyncThunk<CreateConversationResponseData, CreateConversationPayload>(
  'conversations/createConversation',
  async (payload, { extra: { conversationGateway } }) => {
    try {
      return await conversationGateway.createConversation(payload)
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
