import { ChevronDown } from 'lucide-react'

type ScrollToBottomButtonProps = {
  onClick: () => void
  visible: boolean
}

export function TheScrollToBottomButton({ onClick, visible }: Readonly<ScrollToBottomButtonProps>) {
  if (!visible) return null

  return (
    <button
      onClick={onClick}
      className="absolute -top-16 left-0 right-0 z-50 mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-lg ring-1 ring-gray-300 transition-opacity hover:bg-gray-100 dark:bg-gray-800 dark:ring-gray-600 dark:hover:bg-gray-700 md:mb-6"
      aria-label="Scroll to bottom"
    >
      <ChevronDown className="h-6 w-6 text-gray-600 dark:text-gray-200" />
    </button>
  )
}
