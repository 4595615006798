import { addLegalWatchArticleToFavorites } from '@/store/articles/useCases/addLegalWatchArticleToFavorites.ts'
import { getArticle } from '@/store/articles/useCases/getArticle.ts'
import { getArticleBySlug } from '@/store/articles/useCases/getArticleBySlug.ts'
import { listArticles } from '@/store/articles/useCases/listArticles.ts'
import { removeLegalWatchArticleFromFavorites } from '@/store/articles/useCases/removeLegalWatchArticleFromFavorites.ts'
import { RootState } from '@/store/configureStore.ts'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { dateStringToDate } from '@/utils/date'
import {
  Countries,
  DateRangeFilter,
  FilterState,
  RegionFilter,
  TagFilter,
  extractUniqueRegions,
  filterByDateRange,
  filterByRegion,
  filterByTags,
  mapToCountryDetails,
  sortRegions,
} from '@/utils/filterArticle.ts'
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import countriesData from '../../utils/countries.json'

const articlesEntityAdapter = createEntityAdapter<LegalWatchArticle>()

type articleFilterTag = { title: string; label: string }
const countries: Countries = countriesData as Countries

type InitialState = {
  articleFilterTags: articleFilterTag[]
  filters: FilterState
  isFetchingArticles: boolean
  lastFetched: number | null // Timestamp en ms
}
export const articlesSlice = createSlice({
  name: 'articles',
  initialState: articlesEntityAdapter.getInitialState<InitialState>({
    isFetchingArticles: false,
    articleFilterTags: [],
    lastFetched: null,
    filters: {
      regions: [],
      tags: [],
      dateRange: { from: undefined, to: undefined },
    },
  }),
  reducers: {
    addRegionToFilter: (state, action: { payload: RegionFilter | null }) => {
      if (action.payload) {
        state.filters.regions = [...state.filters.regions, action.payload]
      }
    },
    removeRegionToFilter: (state, action: { payload: RegionFilter | null }) => {
      state.filters.regions = state.filters.regions.filter((region) => region.iso !== action.payload?.iso)
    },
    addTagToFilter: (state, action: { payload: TagFilter }) => {
      if (action.payload) {
        state.filters.tags = [...state.filters.tags, action.payload]
      }
    },
    removeTagToFilter: (state, action: { payload: TagFilter }) => {
      state.filters.tags = state.filters.tags.filter((tag) => tag.value !== action.payload.value)
    },
    setDateRangeFilter: (state, action: { payload: DateRangeFilter }) => {
      state.filters.dateRange = action.payload
    },
    resetFilters: (state) => {
      state.filters = { regions: [], tags: [], dateRange: { from: undefined, to: undefined } }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listArticles.fulfilled, (state, action) => {
        articlesEntityAdapter.setAll(state, action.payload)
        state.isFetchingArticles = false
        state.lastFetched = Date.now()
      })
      .addCase(listArticles.pending, (state) => {
        state.isFetchingArticles = true
      })
      .addCase(listArticles.rejected, (state) => {
        state.isFetchingArticles = false
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        articlesEntityAdapter.upsertOne(state, action.payload)
      })
      .addCase(getArticle.rejected, () => {})
      .addCase(getArticleBySlug.fulfilled, (state, action) => {
        articlesEntityAdapter.upsertOne(state, action.payload)
      })
      .addCase(getArticleBySlug.rejected, () => {})
      .addCase(addLegalWatchArticleToFavorites.fulfilled, (state, action) => {
        articlesEntityAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            isFavorite: true,
          },
        })
      })
      .addCase(removeLegalWatchArticleFromFavorites.fulfilled, (state, action) => {
        articlesEntityAdapter.updateOne(state, {
          id: action.meta.arg,
          changes: {
            isFavorite: false,
          },
        })
      })
  },
})

export const {
  addRegionToFilter,
  removeRegionToFilter,
  addTagToFilter,
  removeTagToFilter,
  setDateRangeFilter,
  resetFilters,
} = articlesSlice.actions
export const articlesSelector = articlesEntityAdapter.getSelectors((state: RootState) => state.entities.articles)

export const selectArticleById = (articleId: LegalWatchArticle['id']) => (state: RootState) =>
  articlesSelector.selectById(state, articleId)

export const selectArticleSlug = (slug: LegalWatchArticle['slug'] | undefined) => (state: RootState) => {
  return articlesSelector.selectAll(state).find((article) => article.slug === slug)
}

export const selectAllArticles = (state: RootState) => articlesSelector.selectAll(state)
export const selectDateRange = (state: RootState) => state.entities.articles.filters.dateRange
export const selectRegionsFilter = (state: RootState) => state.entities.articles.filters.regions
export const selectTagsFilter = (state: RootState) => state.entities.articles.filters.tags
export const selectArticleFavorites = createSelector([selectAllArticles], (allArticles) =>
  allArticles.filter((article) => article.isFavorite),
)
export const selectUnReadArticles = createSelector([selectAllArticles], (allArticles) =>
  allArticles.filter((article) => !article.isRead),
)
export const selectFilteredArticles = createSelector(
  [selectAllArticles, (state: RootState) => state.entities.articles.filters],
  (articles, filters) => {
    let filteredArticles = articles
    filteredArticles = filterByRegion(filteredArticles, filters.regions)
    filteredArticles = filterByTags(filteredArticles, filters.tags)
    filteredArticles = filterByDateRange(filteredArticles, filters.dateRange)
    return filteredArticles
  },
)

export const selectHomeArticles = (limit: number) =>
  createSelector([selectAllArticles], (articles) => {
    return articles
      .slice()
      .sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime())
      .slice(0, limit)
  })

export const selectLegalWatchTags = createSelector([selectAllArticles], (articles) => {
  // Flatten the array of tag arrays into a single array and remove duplicates
  const uniqueTags = Array.from(new Set(articles.flatMap((article) => article.tags)))

  // Define a custom compare function for case-insensitive alphabetical sorting
  const customCompare = (a: string, b: string) => {
    return a.localeCompare(b, undefined, { sensitivity: 'base' })
  }

  // Sort using the custom compare function
  uniqueTags.sort(customCompare)

  // Create an array of objects with each tag structured as { value: tag }
  return uniqueTags.map((tag) => ({ value: tag }))
})

export const selectLegalWatchRegions = createSelector([selectAllArticles], (articles) => {
  const regionSet = extractUniqueRegions(articles)
  const desiredOrder: RegionFilter['iso'][] = Object.keys(countries)
  const sortedRegions: RegionFilter['iso'][] = sortRegions(regionSet, desiredOrder)
  return mapToCountryDetails(sortedRegions, countries)
})

export const selectArticlesFromLast24Hours = createSelector([selectAllArticles], (allArticles) => {
  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000) // 24 hours, 60 minutes per hour, 60 seconds per minute, 1000 milliseconds per second
  return allArticles.filter((article) => {
    const articleCreatedAt = dateStringToDate(article.createdAt)
    return articleCreatedAt > twentyFourHoursAgo
  })
})
export const selectNewArticles = createSelector(
  [selectArticlesFromLast24Hours, selectUnReadArticles],
  (articlesFromLast24Hours, unReadArticles) => {
    return articlesFromLast24Hours.filter((article) =>
      unReadArticles.some((unreadArticle) => unreadArticle.id === article.id),
    )
  },
)

export const selectIsFetchingArticles = (state: RootState) => state.entities.articles.isFetchingArticles
