import { HTMLAttributes } from 'react'
import { Button } from '@/components/ui/button.tsx'

interface Props extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void
}
export function CustomizeWatchPanelMobile({ onClick }: Readonly<Props>) {
  return (
    <Button onClick={onClick} className={'w-fit bg-inherit p-0 sm:hidden'} asChild>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18" cy="18" r="18" fill="#5FC999" />
        <path
          d="M12.1667 14.5C12.1667 13.0999 12.1667 12.3998 12.4392 11.865C12.6789 11.3946 13.0613 11.0122 13.5317 10.7725C14.0665 10.5 14.7666 10.5 16.1667 10.5H19.8334C21.2335 10.5 21.9336 10.5 22.4683 10.7725C22.9387 11.0122 23.3212 11.3946 23.5609 11.865C23.8334 12.3998 23.8334 13.0999 23.8334 14.5V25.5L18 22.1667L12.1667 25.5V14.5Z"
          stroke="#FDFDFD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Button>
  )
}
