import { createAppAsyncThunk } from '../../createAppThunk.ts'

export const getTrialPeriodDays = createAppAsyncThunk<number | null, void>(
  'config/fetchTrialPeriodDays',
  async (_, { extra: { appConfigGateway } }) => {
    try {
      return await appConfigGateway.getTrialPeriodDays()
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
