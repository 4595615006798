import { useEffect } from 'react'
import { selectInitialQuestion, setInitialQuestion } from '@/store/conversations/conversationsSlice'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { useAskQuestion } from '@/utils/useAskQuestion.ts'
import { Conversation } from '@/types/conversation.ts'

export const useCreateMessageWithInitialQuestion = ({
  conversation,
  sourceCollectionIds,
}: {
  conversation: Conversation
  sourceCollectionIds: string[]
}) => {
  const dispatch = useAppDispatch()
  const initialQuestion = useAppSelector(selectInitialQuestion)
  const { askQuestion } = useAskQuestion()

  useEffect(() => {
    if (initialQuestion.length > 0) {
      const createInitialMessage = async () => {
        await askQuestion(initialQuestion, conversation, sourceCollectionIds)
        dispatch(setInitialQuestion(''))
      }
      createInitialMessage()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
