import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchArticleListContainer } from '@/components/LegalWatchArticleListContainer.tsx'
import { Tabs, TabsContent } from '@/components/ui/tabs.tsx'
import { useEffect } from 'react'
import { LOCAL_STORAGE_REDIRECTION_KEY, ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { Button } from '@/components/ui/button'
import { Link } from 'react-router-dom'
import { HomePageHeroSection } from '@/components/HomePageHeroSection.tsx'
import { QuestionForm } from '@/components/QuestionForm.tsx'
import { useHomeArticleListHook } from '@/components/home/use-homeArticleList.hook.tsx'
import { MinimalisticArrowIconRight } from '@/components/icons/MinimalisticArrowIconRight.tsx'
import { useHomeHook } from '@/pages/home/use-home-page.hook.tsx'
import { MobileSourceFilterDrawer } from '@/components/MobileSourceFilterDrawer.tsx'
import { useAppSelector } from '@/store/hooks.ts'
import {
  selectIsCreatingConversation,
  selectIsUploadingFileInConversation,
} from '@/store/conversations/conversationsSlice.ts'
import { DpaOrPrivacyPolicyAnalyzer } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'

const MAX_ARTICLES = 3

export function Home() {
  const { articles, isFetchingArticles } = useHomeArticleListHook(MAX_ARTICLES)
  const { process, processExampleDpa, processDpa } = useHomeHook()
  const isCreatingConversation = useAppSelector(selectIsCreatingConversation)
  const isUploadingFileInConversation = useAppSelector(selectIsUploadingFileInConversation)

  useEffect(() => {
    const redirectionValue = localStorage.getItem(LOCAL_STORAGE_REDIRECTION_KEY)
    if (redirectionValue) localStorage.removeItem(LOCAL_STORAGE_REDIRECTION_KEY)
  }, [])

  return (
    <FullHeightLayout className={'max-h-[calc(100vh-3.5rem)] bg-snowMist sm:max-h-screen'}>
      <div className="flex w-full flex-col gap-6 px-4 pb-4 pt-8 transition-all duration-500 ease-in-out 2xl:mx-auto 2xl:max-w-1400">
        <h1 className={'text-[1.75rem] font-semibold leading-9'}>Bienvenue sur Olympe</h1>

        <h2 className="text-lg font-semibold">Que peut faire Olympe pour vous aujourd&#39;hui ?</h2>
        <p className="text-sm text-gray-600">
          Une fonctionnalité manquante vous aiderait ?{' '}
          <Link
            to={import.meta.env.VITE_OLYMPE_CONTACT_URL}
            target={'_blank'}
            className="text-electra hover:text-mintGreen hover:underline"
          >
            Faites-le nous savoir
          </Link>
        </p>

        <HomePageHeroSection>
          <QuestionForm isLoading={isCreatingConversation || isUploadingFileInConversation} process={process} />
          <DpaOrPrivacyPolicyAnalyzer getAnalysisDocumentExample={processExampleDpa} processDpa={processDpa} />
        </HomePageHeroSection>

        <div className="mt-6 flex w-full flex-col items-center justify-between md:flex-row">
          <h2 className="w-full text-xl font-semibold md:w-auto">Dernières actualités de la veille juridique</h2>
          <Button className="mt-4 flex w-full items-center justify-center space-x-2 bg-mintGreen px-4 py-2 text-sm text-white hover:bg-electra md:mt-0 md:w-auto">
            <Link to={ROUTE_PATHS.legalWatch}>Voir toute la veille RGPD</Link>
            <MinimalisticArrowIconRight className="h-5 w-5" />
          </Button>
        </div>

        <Tabs defaultValue={'all'} className={'gap-4'}>
          <TabsContent value="all" className={'flex flex-col gap-5'}>
            <LegalWatchArticleListContainer isFetchingArticles={isFetchingArticles} articles={articles} />
          </TabsContent>
        </Tabs>
      </div>
      <MobileSourceFilterDrawer />
    </FullHeightLayout>
  )
}
