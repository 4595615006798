import { useAppSelector } from '@/store/hooks.ts'
import { selectConversationById, selectConversationsForArticle } from '@/store/conversations/conversationsSlice.ts'
import { selectMessagesForActiveConversation } from '@/store/messages/messagesSlice.ts'
import { useParams } from 'react-router-dom'
import { Conversation } from '@/types/conversation.ts'

export const useMessagesListHook = () => {
  const { conversationId } = useParams() as {
    conversationId: Conversation['id']
  }

  const conversationById = useAppSelector(selectConversationById(conversationId))
  const conversationsForArticle = useAppSelector(selectConversationsForArticle)
  const messages = useAppSelector(selectMessagesForActiveConversation)

  return {
    conversation: conversationById || conversationsForArticle[0],
    messages,
    conversationId,
  }
}
