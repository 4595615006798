import { ConversationGateway, CreateConversationPayload } from './interface/conversation.gateway.ts'
import {
  Conversation,
  CreateConversationResponse,
  CreateConversationResponseData,
  GetConversationByIdResponse,
  GetConversationByIdResponseData,
  ListUserConversationsResponse,
  ListUserConversationsResponseData,
  UpdateConversationByIdBodyData,
  UpdateConversationByIdResponse,
  UpdateConversationByIdResponseData,
} from '../types/conversation.ts'
import { UploadFileInConversationPayload } from '../types/file.ts'
import {
  ListConversationMessagesResponse,
  ListConversationMessagesResponseData,
  Message,
  PostMessageInConversationBodyData,
  UpdateMessageFeedbackResponse,
  UpdateMessageFeedbackResponseData,
} from '../types/message.ts'
import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'
import { WretchResponse } from 'wretch'
import FormDataAddon from 'wretch/addons/formData'

export class WretchConversationGateway implements ConversationGateway {
  private readonly endpoint = '/conversations'
  private olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async updateMessageFeedback(
    conversationId: Conversation['id'],
    messageId: Message['id'],
    feedback: Message['feedback'],
  ): Promise<UpdateMessageFeedbackResponseData> {
    const body = { data: { feedback } }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/messages/${messageId}/feedback`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).patch(body).json<UpdateMessageFeedbackResponse>()
    return response.data
  }

  async createMessage(
    conversationId: Conversation['id'],
    content: Message['content'],
    sourceCollections: Message['sourceCollections'],
  ): Promise<ReadableStreamDefaultReader<Uint8Array> | null> {
    const url = `${this.endpoint}/${conversationId}/messages`
    const body: PostMessageInConversationBodyData = {
      data: {
        content,
        sourceCollections,
      },
    }
    const wretchResponse = await this.olympeGptApi
      .url(url)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .post(body)
      .res()
    return wretchResponse.body?.getReader() ?? null
  }

  async listConversationMessages(conversationId: Conversation['id']): Promise<ListConversationMessagesResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/messages`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListConversationMessagesResponse>()
    return response.data
  }

  async getConversationById(conversationId: string): Promise<GetConversationByIdResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetConversationByIdResponse>()
    return response.data
  }

  uploadFileInConversation({
    fileName,
    file,
    conversationId,
  }: UploadFileInConversationPayload): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/files`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .addon(FormDataAddon)
      .formData({ file, title: fileName })
      .options({
        context: {
          responseType: 'res',
        },
      })
      .post()
      .res()
  }

  async updateConversation(conversation: Conversation): Promise<UpdateConversationByIdResponseData> {
    const body: UpdateConversationByIdBodyData = { data: conversation }
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversation.id}`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).put(body).json<UpdateConversationByIdResponse>()
    return response.data
  }

  async listUserConversations(): Promise<ListUserConversationsResponseData> {
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<ListUserConversationsResponse>()
    return response.data
  }

  deleteFilesInConversation(conversationId: Conversation['id']): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}/files`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }

  deleteConversationById(conversationId: Conversation['id']): Promise<WretchResponse> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/${conversationId}`
    return this.olympeGptApi
      .url(requestInfoOrUrl)
      .options({
        context: {
          responseType: 'res',
        },
      })
      .delete()
      .res()
  }

  async createConversation(payload: CreateConversationPayload): Promise<CreateConversationResponseData> {
    const body = { data: payload }
    const requestInfoOrUrl: RequestInfo | URL = this.endpoint
    const response = await this.olympeGptApi.url(requestInfoOrUrl).post(body).json<CreateConversationResponse>()
    return response.data
  }
}
