import { TheUploadInformationsCard } from '@/components/TheUploadInformationsCard.tsx'
import { FileData } from '@/types/file.ts'

export type FileDataWithSize = FileData & { size: string }
interface Props {
  isUploading: boolean
  file: FileDataWithSize
  uploadFileError: string
}
export function TheUploadInformationsCardContainer({ isUploading, file, uploadFileError }: Readonly<Props>) {
  return (
    isUploading && (
      <TheUploadInformationsCard
        file={file}
        isUploading={isUploading}
        isUploadSuccess={!uploadFileError.length && !isUploading}
      />
    )
  )
}
