import { BackButton } from '@/components/ds/BackButton.tsx'
import { useBackButtonContainerHook } from '@/components/hooks/use-BackButtonContainer.hook.tsx'
import { RoutePathsItem } from '@/ROUTE_PATHS.ts'

interface Props {
  label: string
  path: RoutePathsItem
}
export function BackButtonContainer({ label, path }: Readonly<Props>) {
  const { navigateBack } = useBackButtonContainerHook()
  return <BackButton navigateBack={() => navigateBack(path)} label={label} />
}
