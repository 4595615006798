import { HTMLAttributes } from 'react'

function HeadingMarkdown(props: HTMLAttributes<HTMLHeadingElement>) {
  const { children, ...rest } = props
  return (
    <h3 {...rest} className="text-2xl font-semibold text-primary">
      {children}
    </h3>
  )
}
export default HeadingMarkdown
