import { useToaster } from '@/utils/useToaster.ts'
import { useEffect } from 'react'
import { Toaster } from '@/store/toasterSlice.ts'
import { useAppSelector } from '@/store/hooks.ts'
import { selectCurrentUser } from '@/store/auth/authSlice.ts'

export const useTrialDaysRemaining = () => {
  const { showToast } = useToaster()
  const currentUser = useAppSelector(selectCurrentUser)
  useEffect(() => {
    if (!currentUser) return

    const shouldDisplayTrialDaysRemainingToaster = currentUser?.trialDaysRemaining > 0 && currentUser?.plan === 'free'
    if (shouldDisplayTrialDaysRemainingToaster) {
      const dayOrDays = currentUser?.trialDaysRemaining > 1 ? 'jours' : 'jour'
      const toaster: Toaster = {
        toasterMessage: (
          <>
            Il vous reste <strong>{currentUser?.trialDaysRemaining}</strong> {dayOrDays} sur votre période d’essai.
          </>
        ),
        toasterType: 'trialDays',
        toasterPosition: 'top',
      }
      showToast({ toaster, timeOut: false })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
