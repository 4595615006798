import { OlympeGptApiWretch } from './olympeGptApiWretch.ts'
import { AppConfigGateway } from '@/gateways/interface/appConfig.gateway.ts'
import { GetTrialPeriodDaysResponse } from '@/types/appConfig.ts'

export class WretchAppConfigGateway implements AppConfigGateway {
  private readonly endpoint = '/app'
  private readonly olympeGptApi: OlympeGptApiWretch

  constructor(olympeGptApi: OlympeGptApiWretch) {
    this.olympeGptApi = olympeGptApi
  }

  async getTrialPeriodDays(): Promise<number | null> {
    const requestInfoOrUrl: RequestInfo | URL = `${this.endpoint}/trial-period-days`
    const response = await this.olympeGptApi.url(requestInfoOrUrl).get().json<GetTrialPeriodDaysResponse>()
    return response.data?.trialPeriodDays ?? null
  }
}
