import { Link } from 'react-router-dom'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'
import { Conversation } from '@/types/conversation.ts'
import { ClockIcon } from '../icons/ClockIcon.tsx'
import { TheIconCross } from '../icons/TheIconCross.tsx'
import { TheIconDocumentsFolder } from '../icons/TheIconDocumentsFolder.tsx'
import { TheIconSignout } from '../icons/TheIconSignout.tsx'
import { TheSidebarConversationList } from './TheSidebarConversationList.tsx'
import { TheSidebarFooter } from './TheSidebarFooter.tsx'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { MagicIcon } from '@/components/icons/MagicIcon.tsx'
import { cn } from '@/lib/utils.ts'

type Props = {
  onSignout: () => void
  onClose: () => void
  conversations: Conversation[]
  additionalClasses?: string
}

export function TheSidebarMobile({ onSignout, onClose, additionalClasses = '' }: Readonly<Props>) {
  const { isUserConnected, isFreePlanUser } = useCurrentUserHook()
  return (
    <>
      <div
        className={cn(
          `absolute -left-full z-20 h-screen w-2/3 transform overflow-auto bg-primary pb-5 pl-4 pr-6 pt-4 sm:hidden`,
          additionalClasses,
        )}
      >
        <div className={'flex h-full flex-col justify-between'}>
          <div className={'flex h-full flex-col'}>
            <div>
              <div className="flex items-center justify-between border-b border-solid border-b-bright pb-3">
                <TheIconCross onClick={onClose} className={'h-7 w-7 fill-silver'} />
                <TheIconSignout className={'h-6 w-6 fill-silver'} onClick={onSignout} />
              </div>
              <Link
                to={ROUTE_PATHS.home}
                className={'flex items-center gap-4 border-b border-solid border-label py-8 pl-4 pr-6'}
                onKeyDown={() => {
                  onClose()
                }}
                onClick={() => {
                  onClose()
                }}
              >
                <MagicIcon className={'h-6 w-6 fill-bright'} />
                <span className="text-bright">Assistant Olympe</span>
              </Link>
              <Link
                to={ROUTE_PATHS.legalWatch}
                className="flex items-center gap-4 border-b border-solid border-label py-8 pl-4 pr-6"
                onKeyDown={() => {
                  onClose()
                }}
                onClick={() => {
                  onClose()
                }}
              >
                <ClockIcon className={'h-6 w-6'} />
                <span className="text-bright">Veille juridique</span>
              </Link>
              <Link
                to={ROUTE_PATHS.documents}
                className="flex items-center gap-4 border-b border-solid border-label py-8 pl-4 pr-6"
                onKeyDown={() => {
                  onClose()
                }}
                onClick={() => {
                  onClose()
                }}
              >
                <TheIconDocumentsFolder className={'h-6 w-6'} />
                <span className="text-bright">Mes documents</span>
              </Link>
            </div>
            <TheSidebarConversationList onClose={onClose} />
          </div>
          <TheSidebarFooter isUserConnected={isUserConnected} isFreePlanUser={isFreePlanUser} />
        </div>
      </div>
      <button
        onKeyDown={onClose}
        onClick={onClose}
        className={`${additionalClasses} absolute -left-full z-10 h-screen w-4/5 transform`}
      />
    </>
  )
}
