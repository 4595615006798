import type React from 'react'
import { ScrollArea } from '@/components/ui/scroll-area'
import { cn } from '@/lib/utils.ts'

interface RightDrawerProps {
  isOpen: boolean
  onClose: (value: boolean) => void
  children: React.ReactNode
}

export function RightDrawer({ isOpen, onClose, children }: Readonly<RightDrawerProps>) {
  return (
    <>
      {isOpen && <div className="fixed inset-0 z-40 bg-black bg-opacity-50" onClick={() => onClose(false)} />}
      <div
        className={cn(
          `fixed right-0 top-0 z-50 h-screen overflow-hidden border-l bg-softMint shadow-lg transition-all duration-300 ease-in-out`,
          isOpen ? 'w-[400px]' : 'w-0 opacity-0',
        )}
      >
        <div className="flex h-full flex-col">
          <ScrollArea className="flex-1">
            <div className="p-4">{children}</div>
          </ScrollArea>
        </div>
      </div>
    </>
  )
}
