import { Paperclip } from 'lucide-react'

function DpaFormButtonDefault() {
  return (
    <div className="flex items-center gap-2">
      <Paperclip className="h-4 w-4" />
      <span className={'text-base text-primary'}>Analyser un DPA</span>
    </div>
  )
}
export default DpaFormButtonDefault
