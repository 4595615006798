import { useEffect } from 'react'
import { TheIconDocument } from './icons/TheIconDocument.tsx'
import { TheIconError } from './icons/TheIconError.tsx'
import { TheIconSuccess } from './icons/TheIconSuccess.tsx'
import { TheIconSpinner } from './icons/TheIconSpinner.tsx'
import { FileData } from '../types/file.ts'
import { FileDataWithSize } from '@/components/TheUploadInformationsCardContainer.tsx'

type Props = {
  file: FileDataWithSize
  isUploading: boolean
  isUploadSuccess: boolean
}

export function TheUploadInformationsCard({ file, isUploading, isUploadSuccess }: Readonly<Props>) {
  const setColor = () => {
    return isUploading || isUploadSuccess ? 'text-label' : 'text-error'
  }

  useEffect(() => {}, [isUploading, isUploadSuccess])

  const uploadState = () => {
    if (isUploading) {
      return 'Chargement...'
    } else if (!isUploadSuccess) {
      return 'Echec'
    } else {
      return 'Chargé'
    }
  }

  const renderIcon = () => {
    if (isUploading) {
      return <TheIconSpinner className={'w-6 animate-spin fill-primary text-white dark:text-white'} />
    } else if (!isUploadSuccess) {
      return <TheIconError className={'h-6 w-6 fill-error'} />
    } else {
      return <TheIconSuccess className={'h-6 w-6 fill-primary'} />
    }
  }

  function getSizeInMo(file: FileData & { size: string }) {
    const sizeInMo = Number(file?.size) / 1000 / 1000 || 0 // Using optional chaining and providing a default value of 0
    return sizeInMo.toFixed(2) // Limit decimal places to two
  }

  return (
    <div className="flex w-full items-center justify-between gap-2 rounded-xl border border-solid border-bInput p-3 lg:gap-6">
      <div className="error flex w-full items-center gap-4">
        <div className="flex h-10 w-10 items-center justify-center rounded-md bg-brightGray sm:w-10">
          <TheIconDocument className={'h-6 w-6 fill-primary'} />
        </div>
        <div className="flex w-52 flex-col sm:w-full">
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">{file?.title}</span>
          <div>
            PDF, {getSizeInMo(file)}Mo - <span className={setColor()}>{uploadState()}</span>
          </div>
        </div>
      </div>
      {renderIcon()}
    </div>
  )
}
