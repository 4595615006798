import { ReactNode } from 'react'

type Props = {
  contentVisible: boolean
  onClick: () => void
  positionClass?: string
  styleBasedOnScreenSize?: string
  animationClass: string
  children: ReactNode
}

export function BaseModal(props: Readonly<Props>) {
  const positionClass = props.positionClass ?? ''
  const styleBasedOnScreenSize = props.styleBasedOnScreenSize ?? ''
  return (
    <div
      id="slideover-container"
      className={`opacity fixed inset-0 z-20 flex h-full w-full overflow-scroll ${
        props.contentVisible ? '' : 'invisible'
      }`}
    >
      <div
        onClick={props.onClick}
        id="slideover-bg"
        className={`fixed inset-0 z-10 h-full w-full bg-primary transition-all duration-500 ease-out ${
          props.contentVisible ? 'opacity-50' : 'opacity-0'
        }`}
      />
      <div
        id="slideover"
        className={`absolute ${positionClass} z-10 overflow-scroll ${styleBasedOnScreenSize}  transition-all duration-300 ease-out ${
          props.contentVisible ? '' : props.animationClass
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}
