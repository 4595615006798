import { createAppAsyncThunk } from '@/store/createAppThunk.ts'
import { Conversation } from '@/types/conversation.ts'
import { selectInitialQuestion } from '@/store/conversations/conversationsSlice.ts'
import { RootState } from '@/store/configureStore'

export const listConversationMessages = createAppAsyncThunk(
  'messages/listConversationMessages',
  async (conversationId: Conversation['id'], { extra: { conversationGateway }, getState }) => {
    try {
      const messages = await conversationGateway.listConversationMessages(conversationId)
      const messagesWithConversationId = messages.map((message) => ({ ...message, conversationId }))
      const state = getState() as RootState
      return { messages: messagesWithConversationId, initialQuestion: selectInitialQuestion(state) }
    } catch (error) {
      throw new Error((error as Error).message)
    }
  },
)
