import { NestedCheckboxList } from '@/components/NestedCheckboxList.tsx'
import { useConversationPageHook } from '@/pages/conversations/[conversationId]/use-conversation-page.hook.tsx'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'
import { useCurrentUserHook } from '@/components/hooks/use-CurrentUser.hook.tsx'
import { Drawer, DrawerContent, DrawerTitle } from '@/components/ui/drawer.tsx'

export function MobileSourceFilterDrawer() {
  const { isMobileSourceFiltersDrawerDisplayed, handleDisplayFilterMobile } = useConversationPageHook()
  const { groups, sourceCollectionIds, handleSelectedSourceCollectionsChange } = useSourceCollections()
  const { isUserConnected } = useCurrentUserHook()

  if (groups.length === 0) return null
  return (
    <Drawer open={isMobileSourceFiltersDrawerDisplayed} onOpenChange={(open) => handleDisplayFilterMobile(open)}>
      <DrawerContent aria-describedby={undefined} className={'items-center'}>
        <DrawerTitle></DrawerTitle>
        <div
          className={
            'mb-12 mt-5 flex h-full w-full items-center justify-center overflow-auto px-5 md:my-5 lg:max-w-full'
          }
        >
          <NestedCheckboxList
            title={'FILTRER LES SOURCES'}
            className={
              'm-auto my-0 flex max-h-halfVh w-full flex-col justify-center gap-3 overflow-scroll overflow-y-auto rounded bg-bright pb-8 pl-3 pt-8 lg:hidden'
            }
            groups={groups}
            selectedItems={sourceCollectionIds}
            onSelectedItemsChange={handleSelectedSourceCollectionsChange}
            isUserConnected={isUserConnected}
          />
        </div>
      </DrawerContent>
    </Drawer>
  )
}
