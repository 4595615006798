import React, { ReactNode } from 'react'

export function HomePageHeroSection({ children }: Readonly<{ children: ReactNode }>) {
  return (
    <div className="grid w-full grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-2">
      {React.Children.map(children, (child) => (
        <>{child}</>
      ))}
    </div>
  )
}
