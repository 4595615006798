import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'
import { AnalyzeDpaResponseDto } from '@/modules/document-analysis/schemas.ts'

export const analyzeDpaMapper = () => {
  const toEntity = (dto: AnalyzeDpaResponseDto): ConversationEntity => {
    return {
      createdAt: dto.data.createdAt,
      currentFilesInfos: dto.data.currentFilesInfos.map((fileInfos) => {
        return { ...fileInfos, uploadStatus: 'UPLOADED' }
      }),
      name: dto.data.name,
      type: dto.data.type,
      id: dto.data.id,
    }
  }
  return {
    toEntity,
  }
}
