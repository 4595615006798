import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectActiveConversationId, setActiveConversationId } from '@/store/conversations/conversationsSlice'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { ConversationEntity } from '@/modules/conversation/domain/entities/ConversationEntity.ts'

export const useFetchMessages = (conversationId?: ConversationEntity['id']) => {
  const dispatch = useAppDispatch()
  const activeConversationId = useSelector(selectActiveConversationId)

  useEffect(() => {
    if (!conversationId) return
    if (activeConversationId !== conversationId) dispatch(setActiveConversationId(conversationId))
    const fetchMessages = async () => await dispatch(listConversationMessages(conversationId))
    fetchMessages()
  }, [conversationId, dispatch, activeConversationId])
}
