import { ChangeEvent, useState } from 'react'
import { useAskQuestion } from '@/utils/useAskQuestion.ts'
import { selectConversationForArticle } from '@/store/conversations/conversationsSlice.ts'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { selectIsStreaming, selectMessagesForActiveConversation } from '@/store/messages/messagesSlice'
import { deleteConversationById } from '@/store/conversations/useCases/deleteConversationById.ts'
import { createConversation } from '@/store/conversations/useCases/createConversation.ts'
import { useParams } from 'react-router-dom'
import { LegalWatchArticle } from '@/types/legalWatchArticle.ts'
import { selectArticleSlug } from '@/store/articles/articlesSlice.ts'
import { useSourceCollections } from '@/utils/useSourceCollections.ts'

export const useDiscussionPanelInput = () => {
  const dispatch = useAppDispatch()
  const [inputValue, setInputValue] = useState('')
  const isStreaming = useAppSelector(selectIsStreaming)
  const messages = useAppSelector(selectMessagesForActiveConversation)
  const { slug } = useParams<{ slug: LegalWatchArticle['slug'] }>()
  const articleBySlug = useAppSelector(selectArticleSlug(slug))
  const { sourceCollectionIds } = useSourceCollections()
  const { askQuestion } = useAskQuestion()
  const conversationForArticle = useAppSelector(selectConversationForArticle(articleBySlug?.id))
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value)
  }

  const onSubmit = async () => {
    setInputValue('')
    if (conversationForArticle) {
      await askQuestion(inputValue, conversationForArticle, sourceCollectionIds)
    }
  }

  const onRestartConversation = () => {
    if (!articleBySlug) return
    if (conversationForArticle) {
      dispatch(deleteConversationById(conversationForArticle.id))
    }
    dispatch(createConversation({ type: 'ARTICLE', articleId: articleBySlug.id }))
  }

  const handleKeyDown = async (e: { key: string; shiftKey: boolean }) => {
    if (e.key === 'Enter' && !e.shiftKey && inputValue.trim() !== '') {
      await onSubmit()
    }
  }
  return {
    inputValue,
    onInputChange,
    onSubmit,
    isStreaming,
    messages,
    onRestartConversation,
    handleKeyDown,
  }
}
