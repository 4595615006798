import { articlesSlice } from '@/store/articles/articlesSlice.ts'
import { textsSlice } from '@/store/texts/textsSlice.ts'
import { combineReducers } from 'redux'
import { authSlice } from './auth/authSlice.ts'
import { conversationSnapshotSlice } from './conversationSnapshot/conversationSnapshotSlice.ts'
import { conversationsSlice } from './conversations/conversationsSlice.ts'
import { filesSlice } from './files/filesSlice.ts'
import { messagesSlice } from './messages/messagesSlice.ts'
import { sourceCollectionsSlice } from './sourceCollections/sourceCollectionsSlice.ts'
import { steppersSlice } from './stepper/steppersSlice.ts'
import { tagsSlice } from './tags/tagSlice.ts'
import { toasterSlice } from './toasterSlice.ts'
import { modalsSlice } from '@/store/modal/modalsSlice.ts'
import { drawersSlice } from '@/store/drawers/drawersSlice.ts'
import { documentAnalysisSlice } from '@/modules/document-analysis/documentAnalysisSlice.ts'

export const entitiesReducer = combineReducers({
  [conversationsSlice.name]: conversationsSlice.reducer,
  [conversationSnapshotSlice.name]: conversationSnapshotSlice.reducer,
  [sourceCollectionsSlice.name]: sourceCollectionsSlice.reducer,
  [filesSlice.name]: filesSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [messagesSlice.name]: messagesSlice.reducer,
  [toasterSlice.name]: toasterSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [steppersSlice.name]: steppersSlice.reducer,
  [articlesSlice.name]: articlesSlice.reducer,
  [textsSlice.name]: textsSlice.reducer,
  [drawersSlice.name]: drawersSlice.reducer,
  [documentAnalysisSlice.name]: documentAnalysisSlice.reducer,
})
