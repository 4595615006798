import {
  selectArticleFavorites,
  selectHomeArticles,
  selectIsFetchingArticles,
  selectUnReadArticles,
} from '@/store/articles/articlesSlice'
import { useAppSelector } from '@/store/hooks.ts'

export function useHomeArticleListHook(limit = 3) {
  const articles = useAppSelector(selectHomeArticles(limit))
  const unReadArticles = useAppSelector(selectUnReadArticles)
  const favoritesArticles = useAppSelector(selectArticleFavorites)
  const isFetchingArticles = useAppSelector(selectIsFetchingArticles)

  return {
    articles,
    unReadArticles,
    favoritesArticles,
    isFetchingArticles,
  }
}
