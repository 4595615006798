import React from 'react'
import { useDocumentTitle } from '@/utils/useDocumentTitle.ts'
import { cn } from '@/lib/utils.ts'

interface Props {
  title: string
  children: React.ReactNode
}

const usePrefixedTitle = (title: string) => {
  const titlePrefix = 'Olympe | '
  useDocumentTitle(`${titlePrefix}${title}`)
}

const usePageBackgroundColor = (title: string) => {
  const titleToColorMap: Record<string, string> = {
    'Veille juridique': 'bg-snowMist',
    'Article de veille juridique': 'bg-snowMist',
    'Conversation en cours': 'bg-snowMist',
    'Article de veille juridique favoris': 'bg-snowMist',
  }

  return titleToColorMap[title] || ''
}

export function Page({ title, children }: Readonly<Props>) {
  usePrefixedTitle(title)
  const bgColor = usePageBackgroundColor(title)

  return <div className={cn(`h-[calc(100vh-3.5rem)] sm:h-screen`, bgColor)}>{children}</div>
}
