import { FullHeightLayout } from '@/pages/prompt-tutorial/FullHeightLayout.tsx'
import { LegalWatchContentLayout } from '@/components/legalWatch/LegalWatchContentLayout.tsx'
import { BackButtonContainer } from '@/components/BackButtonContainer.tsx'
import { LegalWatchArticleListContainer } from '@/components/LegalWatchArticleListContainer.tsx'
import { useLegalWatchArticleListHook } from '@/components/legalWatch/use-legalWatchArticleList.hook.tsx'
import { ROUTE_PATHS } from '@/ROUTE_PATHS.ts'

export function LegalWatchFavorites() {
  const { favoritesArticles } = useLegalWatchArticleListHook()

  return (
    <FullHeightLayout className={'sm:py-8'}>
      <LegalWatchContentLayout>
        <BackButtonContainer path={ROUTE_PATHS.legalWatch} label={'Veille juridique'} />
        <h1 className={'text-[1.75rem] font-semibold leading-9'}>Mes favoris</h1>
        {favoritesArticles.length === 0 ? (
          <div>{"Aucun article n'a été ajouté aux favoris"}</div>
        ) : (
          <LegalWatchArticleListContainer articles={favoritesArticles} />
        )}
      </LegalWatchContentLayout>
    </FullHeightLayout>
  )
}
