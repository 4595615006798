import { RootState } from '@/store/configureStore.ts'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type InitialState = {
  isLegalWatchDrawerDisplayed: boolean
  isSourceListDrawerDisplayed: boolean
  isMobileSourceFiltersDrawerDisplayed: boolean
}
const initialState: InitialState = {
  isLegalWatchDrawerDisplayed: true,
  isSourceListDrawerDisplayed: false,
  isMobileSourceFiltersDrawerDisplayed: false,
}
export const drawersSlice = createSlice({
  name: 'drawers',
  initialState,
  reducers: {
    setSourceListDrawerDisplayed: (state, action: PayloadAction<boolean>) => {
      state.isSourceListDrawerDisplayed = action.payload
    },
    setIsMobileSourceFiltersDrawerDisplayed: (state, action: PayloadAction<boolean>) => {
      state.isMobileSourceFiltersDrawerDisplayed = action.payload
    },
  },
})

export const { setSourceListDrawerDisplayed, setIsMobileSourceFiltersDrawerDisplayed } = drawersSlice.actions

export const selectIsDrawerDisplayed = (state: RootState) => state.entities.drawers.isLegalWatchDrawerDisplayed
export const selectIsSourceListDrawerDisplayed = (state: RootState) =>
  state.entities.drawers.isSourceListDrawerDisplayed
export const selectIsMobileSourceFiltersDrawerDisplayed = (state: RootState) =>
  state.entities.drawers.isMobileSourceFiltersDrawerDisplayed
