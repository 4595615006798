import { MessageItemContainer } from './MessageItemContainer.tsx'
import { useEffect, useRef } from 'react'
import { Conversation } from '@/types/conversation.ts'
import AvatarIcon from '@/components/AvatarIcon.tsx'
import TheConversationAILoader from '@/components/TheConversationAILoader.tsx'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'
import { ConversationSystemMessageWithDocuments } from '@/components/ConversationSystemMessageWithDocuments.tsx'
import { cn } from '@/lib/utils.ts'
import { MessageSystemForDPA } from '@/components/MessageSystemForDPA.tsx'
import { ConversationType } from '@/modules/conversation/domain/entities/ConversationEntity.ts'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  messages: MessageEntity[]
  className?: string
  conversation: Conversation
}

export function MessagesList({ messages, className, conversation }: Readonly<Props>) {
  const ref = useRef<HTMLDivElement>(null)
  const previousMessagesLength = useRef(0)
  const lastMessage = messages.length > 0 ? messages[messages.length - 1] : null
  useEffect(() => {
    // This code apply a different kind of scrolling depending on
    // whether the number of messages changes (for example at page reload)
    // or only if the content of the array changes (for exemple when the last message is streamed)
    if (conversation?.type === ConversationType.ANALYZE) return
    if (previousMessagesLength.current !== messages.length) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    } else {
      ref.current?.scrollIntoView({
        block: 'end',
      })
    }
    previousMessagesLength.current = messages.length
  }, [messages])

  return (
    <div className={cn('mx-auto max-w-[800px]', className)}>
      {!!conversation?.currentFilesInfos.length && conversation?.type !== 'ANALYZE' && (
        <ConversationSystemMessageWithDocuments />
      )}
      <MessageSystemForDPA conversation={conversation} />
      {messages.map((message) => (
        <MessageItemContainer conversation={conversation} key={message.id} message={message} />
      ))}
      {lastMessage?.type === 'HUMAN' ? (
        <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-center sm:gap-4">
          <AvatarIcon type="AI" />
          <TheConversationAILoader />
        </div>
      ) : null}
      <div ref={ref} />
    </div>
  )
}
