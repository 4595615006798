import { LoaderFunction, Navigate, Outlet } from 'react-router-dom'
import { LOCAL_STORAGE_REDIRECTION_KEY, LOCAL_STORAGE_SLUG_KEY, ROUTE_PATHS } from '../../ROUTE_PATHS.ts'
import { CreateUserResponseData } from '../../types/user.ts'
import { olympeGptApiWretch } from '../../gateways/olympeGptApiWretch.ts'
import { WretchAccountGateway } from '../../gateways/WretchAccount.gateway.ts'
import { AppStore } from '../../store/configureStore.ts'
import { setCurrentUser } from '../../store/auth/authSlice.ts'
import { useTrialDaysRemaining } from '@/utils/useTrialDaysRemaining.tsx'

export let currentUser: CreateUserResponseData | null = null

export const currentUserLoader =
  ({ store }: { store: AppStore }): LoaderFunction =>
  async ({ params }) => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryParams = Object.fromEntries(urlParams.entries())

    if (currentUser) {
      return currentUser
    }

    if (params[LOCAL_STORAGE_SLUG_KEY]) {
      localStorage.setItem(LOCAL_STORAGE_SLUG_KEY, params[LOCAL_STORAGE_SLUG_KEY])
    }

    if (queryParams[LOCAL_STORAGE_REDIRECTION_KEY]) {
      localStorage.setItem(LOCAL_STORAGE_REDIRECTION_KEY, queryParams[LOCAL_STORAGE_REDIRECTION_KEY])
    }

    const accountGateway = new WretchAccountGateway(olympeGptApiWretch)
    try {
      currentUser = await accountGateway.getCurrentUser()
      store.dispatch(setCurrentUser(currentUser))
      return currentUser
    } catch (error) {
      window.location.href = ROUTE_PATHS.login
    }
    return null
  }

export function ProtectedRoutes() {
  useTrialDaysRemaining()
  if (!currentUser) {
    return <Navigate to={ROUTE_PATHS.login} />
  }

  return <Outlet />
}
