import { Conversation } from '@/types/conversation.ts'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'
import { Button } from '@/components/ui/button.tsx'

interface Props {
  conversation: Conversation
  message: MessageEntity
  onClick: () => void
}
export function SourcesButton(props: Readonly<Props>) {
  if (
    props.conversation.type !== 'MESSAGE_ONLY' ||
    !props.message.sources ||
    props.message.sources.length === 0 ||
    props.message.type !== 'AI'
  ) {
    return null
  }
  return (
    <div className="flex">
      <Button
        className="mb-4 ml-auto"
        variant="boutonDefault"
        onClick={props.onClick}
        disabled={props.message.sources.length < 1}
      >
        {`Voir les ${props.message.sources.length} sources officielles`}
      </Button>
    </div>
  )
}
