import { useState } from 'react'
import { fileGateway } from '@/main.tsx'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { selectFilePreviewModal, setPdfData, toggleFilePreviewModal } from '@/store/modal/modalsSlice.ts'
import { FileData } from '@/types/file.ts'

export const useFilePreviewModalHook = () => {
  const dispatch = useAppDispatch()
  const [previewFileModalWidth, setPreviewFileModalWidth] = useState<'small' | 'fullScreen'>('small')
  const filePreviewModal = useAppSelector(selectFilePreviewModal)
  const resizeModal = () => {
    previewFileModalWidth === 'small' ? setPreviewFileModalWidth('fullScreen') : setPreviewFileModalWidth('small')
  }

  const handleFilePreview = async (file: FileData) => {
    const newBlob = await fileGateway.readFile(file.id)
    const pdfData = URL.createObjectURL(newBlob)
    dispatch(setPdfData(pdfData))
    dispatch(toggleFilePreviewModal())
  }

  const handleSetPreviewFile = () => dispatch(toggleFilePreviewModal())

  return {
    handleSetPreviewFile,
    handleFilePreview,
    resizeModal,
    previewFileModalWidth,
    previewFile: filePreviewModal.isOpen,
    pdfData: filePreviewModal.pdfData,
    style: `right-0 h-screen mr-4 bg-bright ${previewFileModalWidth === 'small' ? 'w-[31rem]' : 'w-full'}`,
  }
}
