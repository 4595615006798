import AvatarIcon from '@/components/AvatarIcon.tsx'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'

export function MessageItemUser(props: Readonly<{ message: MessageEntity }>) {
  return (
    <div className={'mx-auto my-4 max-w-[800px]'}>
      <div className={`flex flex-col items-start gap-2 sm:flex-row-reverse sm:gap-4`}>
        <div className="m-0 h-fit w-fit p-0">{<AvatarIcon type={props.message.type} />}</div>
        <p className={'w-full text-end font-semibold'}>{props.message.content}</p>
      </div>
    </div>
  )
}
