import { HTMLAttributes } from 'react'

function ParagraphMarkdown(props: HTMLAttributes<HTMLParagraphElement>) {
  const { children, ...rest } = props
  return (
    <p {...rest} className="w-full text-justify">
      {children}
    </p>
  )
}
export default ParagraphMarkdown
