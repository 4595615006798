import { createSlice } from '@reduxjs/toolkit'
import { analyzeDpaUseCase } from '@/modules/document-analysis/use-cases/analyzeDpaUseCase.ts'
import { fetchExampleDpaUseCase } from '@/modules/document-analysis/use-cases/fetchExampleDpaUseCase.ts'
import { messagesEntityAdapter } from '@/store/messages/messagesSlice.ts'
import { RootState } from '@/store/configureStore.ts'
import { DpaExamples } from '@/components/DpaOrPrivacyPolicyAnalyzer.tsx'

export enum AnalyseStatus {
  EMPTY = 'EMPTY',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export const documentAnalysisSlice = createSlice({
  name: 'documentAnalysis',
  initialState: messagesEntityAdapter.getInitialState({
    analyseStatus: AnalyseStatus.EMPTY,
    isFetchingExampleDpa: false,
    exampleFileName: '',
  }),
  reducers: {
    setAnalyseStatusToEmpty: (state) => {
      state.analyseStatus = AnalyseStatus.EMPTY
    },
    setExampleFileName: (state, action: { payload: DpaExamples }) => {
      state.exampleFileName = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(analyzeDpaUseCase.fulfilled, (state) => {
      state.analyseStatus = AnalyseStatus.COMPLETE
    })
    builder.addCase(analyzeDpaUseCase.pending, (state) => {
      state.analyseStatus = AnalyseStatus.IN_PROGRESS
    })
    builder.addCase(analyzeDpaUseCase.rejected, (state) => {
      state.analyseStatus = AnalyseStatus.EMPTY
    })
    builder.addCase(fetchExampleDpaUseCase.fulfilled, (state) => {
      state.analyseStatus = AnalyseStatus.COMPLETE
      state.isFetchingExampleDpa = false
    })
    builder.addCase(fetchExampleDpaUseCase.pending, (state) => {
      state.analyseStatus = AnalyseStatus.IN_PROGRESS
      state.isFetchingExampleDpa = true
    })
    builder.addCase(fetchExampleDpaUseCase.rejected, (state) => {
      state.analyseStatus = AnalyseStatus.EMPTY
      state.isFetchingExampleDpa = false
    })
  },
})

export const { setAnalyseStatusToEmpty, setExampleFileName } = documentAnalysisSlice.actions

export const selectAnalyseStatus = (state: RootState) => state.entities.documentAnalysis.analyseStatus
export const selectExampleFileName = (state: RootState) => state.entities.documentAnalysis.exampleFileName
