import { OlympeError } from '@/modules/shared/OlympeError.ts'

export class FileEntity {
  public file: File
  private static readonly MAX_FILE_SIZE = 20 * 1024 * 1024
  private constructor(file: File) {
    this.file = file
  }

  public static create(file: File): FileEntity | OlympeError {
    if (file.size > this.MAX_FILE_SIZE) {
      return new OlympeError('Le fichier dépasse la limite de taille de 20 Mo')
    }
    return new FileEntity(file)
  }
}
