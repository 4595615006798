import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx'
import { Badge } from '@/components/ui/badge.tsx'
import { DotIcon } from '@/components/icons/DotIcon.tsx'
import { LegalWatchArticle, ListUserConversationsForLegalWatchArticleResponse } from '@/types/legalWatchArticle.ts'
import { ArticleTagsListContainer } from '@/components/ArticleTagsListContainer.tsx'
import { nanoid } from '@reduxjs/toolkit'
import { ArticleTag } from '@/components/ds/ArticleTag.tsx'
import { MarkdownLinkEnhancer } from '@/components/MarkdownLinkEnhancer.tsx'
import { Button } from '@/components/ui/button.tsx'
import { Bookmark } from '@/components/icons/Bookmark.tsx'
import { useAppDispatch, useAppSelector } from '@/store/hooks.ts'
import { openAuthModal } from '@/store/modal/modalsSlice.ts'
import { selectConversationIdByArticleId } from '@/store/conversations/conversationsSlice.ts'
import { useFetchMessages } from '@/components/hooks/useFetchMessages.tsx'

export type LegalWatchArticleDetailsProps = {
  article: LegalWatchArticle
  conversationsForArticle: ListUserConversationsForLegalWatchArticleResponse['data']
  filterArticlesByTag: (tag: string) => void
  onStartConversation: (articleId: LegalWatchArticle['id']) => void
  onToggleFavorite: (id: LegalWatchArticle['id'], isFavorite: LegalWatchArticle['isFavorite']) => void
  isUserConnected: boolean
}

export function LegalWatchArticleDetails({
  article,
  conversationsForArticle,
  filterArticlesByTag,
  onStartConversation,
  onToggleFavorite,
  isUserConnected,
}: Readonly<LegalWatchArticleDetailsProps>) {
  const dispatch = useAppDispatch()
  const conversationId = useAppSelector(selectConversationIdByArticleId(article.id))

  useFetchMessages(conversationId)

  return (
    <Card className={'rounded-2xl'}>
      <CardHeader className={'gap-6'}>
        <div className={'flex flex-col gap-3'}>
          <div className={'flex gap-[1.25rem]'}>
            <div>
              <CardTitle className={'text-2xl'}>{article.title}</CardTitle>
            </div>
            <div>
              <Bookmark
                onClick={(event) => {
                  event.stopPropagation()
                  if (isUserConnected) {
                    onToggleFavorite(article.id, article.isFavorite)
                  } else {
                    dispatch(openAuthModal())
                  }
                }}
                className={`${article.isFavorite ? 'fill-mintGreen stroke-mintGreen' : 'fill-bright stroke-mintGreen group-hover:fill-softMint'} hover:cursor-pointer`}
              />
            </div>
          </div>
          <div className={'flex flex-col-reverse items-start justify-between gap-3.5 sm:flex-row sm:items-center'}>
            <ArticleTagsListContainer>
              {article.tags.map((tag) => (
                <ArticleTag filterByTag={filterArticlesByTag} key={nanoid()} tag={tag} />
              ))}
            </ArticleTagsListContainer>
            {conversationsForArticle.length === 0 && (
              <Button
                onClick={() => onStartConversation(article.id)}
                className={`group hidden w-[8.43rem] items-center gap-2 rounded-lg border-2 border-solid border-transparent bg-mintGreen px-4 py-2 hover:cursor-pointer hover:border-mintGreen hover:bg-bright lg:flex`}
              >
                <span className={'text-sm font-semibold text-bright group-hover:text-mintGreen'}>
                  Poser une question
                </span>
              </Button>
            )}
          </div>

          <div className={'flex items-center'}>
            <Badge variant={'custom'} className={'mr-3.5 bg-mintMist text-mintGreen'}>
              {article.region}
            </Badge>
            <CardDescription className={'text-sm font-normal uppercase text-label'}>{article.source}</CardDescription>
            <DotIcon className={'mx-2'} />
            <CardDescription className={'text-xs text-label'}>{article.createdAt}</CardDescription>
          </div>
        </div>
      </CardHeader>
      <CardContent className={'pb-4'}>
        <MarkdownLinkEnhancer content={article.content} />
      </CardContent>
    </Card>
  )
}
