import { Message } from '../types/message'
import { TheIconAvatarHuman } from './icons/TheIconAvatarHuman'
import { TheIconOlympeLogoWithoutText } from '@/components/icons/TheIconOlympeLogoWithoutText.tsx'

function AvatarIcon({ type }: { type: Message['type'] }) {
  const Icon = type === 'HUMAN' ? TheIconAvatarHuman : TheIconOlympeLogoWithoutText
  return <Icon width="38" height="38" />
}

export default AvatarIcon
