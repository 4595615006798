import AvatarIcon from '@/components/AvatarIcon.tsx'
import ReactMarkdown, { Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Conversation } from '@/types/conversation.ts'
import { MessageEntity } from '@/modules/message/domain/entities/MessageEntity.ts'
import { cn } from '@/lib/utils.ts'
import { TEMPORARY_AI_ID } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { SourcesButton } from './SourcesButton'
import ParagraphMarkdown from '@/components/ParagraphMarkdown.tsx'
import TableMarkdown from '@/components/TableMarkdown.tsx'
import HeadingMarkdown from '@/components/HeadingMarkdown.tsx'
import LinkMarkdown from '@/components/LinkMarkdown.tsx'
import TableDataCellMarkdown from '@/components/TableDataCellMarkdown.tsx'
import TableHeadCellMarkdown from '@/components/TableHeadCellMarkdown.tsx'
import { ReactNode } from 'react'

interface Props {
  conversation: Conversation
  message: MessageEntity
  onClick: () => void
  children: ReactNode
}

export function MessageItemAI(props: Readonly<Props>) {
  const ctaDisplayClasses = props.message.id === TEMPORARY_AI_ID ? 'hidden' : ''

  if (props.message.type !== 'AI') return null

  const markdownComponents: Components = {
    p: ParagraphMarkdown,
    h3: HeadingMarkdown,
    table: TableMarkdown,
    th: TableHeadCellMarkdown,
    td: TableDataCellMarkdown,
    a: LinkMarkdown,
  }

  return (
    <div className={'mx-auto my-8 max-w-[1000px]'}>
      <SourcesButton conversation={props.conversation} message={props.message} onClick={props.onClick} />
      <div className={`flex flex-col gap-2 sm:flex-row sm:gap-4`}>
        <div className="m-0 h-fit w-fit p-0">{<AvatarIcon type={props.message.type} />}</div>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          className={cn('text-base leading-6')}
          components={markdownComponents}
        >
          {props.message.content}
        </ReactMarkdown>
      </div>
      <div className={cn(`w-full sm:mb-4 sm:ml-14 sm:w-fit`, ctaDisplayClasses)}>{props.children}</div>
    </div>
  )
}
