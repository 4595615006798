export type MessageType = 'HUMAN' | 'AI' | 'SYSTEM' | 'GENERIC'
export type MessageFeedbackType = 'THUMBS_UP' | 'THUMBS_DOWN' | 'NULL' | undefined
export enum MessageFeedbackEnum {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
  NULL = 'NULL',
}
type MessageSource = {
  content: string
  title: string
  url: string
  pageNumber?: number
}
export type MessageSources = MessageSource[]

export interface MessageEntityProps {
  id: string
  content: string
  createdAt: string
  type: MessageType
  sources?: MessageSource[]
  feedback?: MessageFeedbackType
  conversationId?: string
  sourceCollections?: string[]
}

export class MessageEntity {
  public id: string
  public content: string
  public createdAt: string
  public type: MessageType
  public sources?: MessageSources
  public feedback?: MessageFeedbackType
  public conversationId?: string
  public sourceCollections?: string[]
  constructor(props: MessageEntityProps) {
    this.id = props.id
    this.content = props.content
    this.createdAt = props.createdAt
    this.type = props.type
    this.sources = props.sources
    this.feedback = props.feedback
    this.conversationId = props.conversationId
    this.sourceCollections = props.sourceCollections
  }
}
