import { AnchorHTMLAttributes } from 'react'

function LinkMarkdown(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
  const { children, ...rest } = props
  return (
    <a {...rest} className="text-electra hover:text-mintGreen hover:underline" target="_blank">
      {children}
    </a>
  )
}

export default LinkMarkdown
