import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../configureStore.ts'

export interface Step {
  isActive: boolean
  id: number
}

export type IndustryId =
  | 'INDUSTRIE'
  | 'BANQUE_ASSURANCE'
  | 'IMMOBILIER'
  | 'SANTE'
  | 'PHARMACEUTIQUE'
  | 'TRANSPORTS'
  | 'COLLECTIVITE_ADMINISTRATION'
  | 'SERVICES_JURIDIQUES'
  | 'SERVICES_ENTREPRISES'
  | 'SERVICES_PERSONNE'
  | 'ARTISANAT'
  | 'COMMERCE'
  | 'AGRICULTURE_AGROALIMENTAIRE'
  | ''

export type Industry =
  | {
      id: IndustryId
    }
  | { id: 'OTHER'; otherValue: string }

export type JobTitle = 'DPO Interne' | 'DPO Externe' | 'Autre'

export interface RegisterForm {
  email: string
  password: string
  firstname: string
  lastname: string
  jobTitle: string
  cgu: boolean
  industry: Industry
}

interface SteppersState {
  registerStepper: {
    steps: Step[]
    form: RegisterForm
  }
  onboardingStepper: Step[]
}

const initialState: SteppersState = {
  registerStepper: {
    steps: [
      { isActive: true, id: 0 },
      { isActive: false, id: 1 },
      { isActive: false, id: 2 },
      { isActive: false, id: 3 },
    ],
    form: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      jobTitle: '',
      cgu: false,
      industry: {
        id: '',
      },
    },
  },
  onboardingStepper: [
    {
      id: 0,
      isActive: true,
    },
    {
      id: 1,
      isActive: false,
    },
    {
      id: 2,
      isActive: false,
    },
    {
      id: 3,
      isActive: false,
    },
    {
      id: 4,
      isActive: false,
    },
  ],
}

export const steppersSlice = createSlice({
  name: 'steppers',
  initialState,
  reducers: {
    resetRegisterStepper: (state) => {
      state.registerStepper = initialState.registerStepper
    },
    nextRegisterStep: (state) => {
      const updatedSteps = [...state.registerStepper.steps]
      const currentStepIndex = updatedSteps.findIndex((step) => step.isActive)
      if (currentStepIndex === -1) return
      updatedSteps[currentStepIndex].isActive = false
      const nextStep = currentStepIndex + 1
      const nextStepToSetActive = nextStep > updatedSteps.length - 1 ? 0 : nextStep
      updatedSteps[nextStepToSetActive].isActive = true
      state.registerStepper.steps = updatedSteps
    },
    previousRegisterStep: (state) => {
      const updatedSteps = [...state.registerStepper.steps]
      const currentStepIndex = updatedSteps.findIndex((step) => step.isActive)
      if (currentStepIndex === -1) return
      updatedSteps[currentStepIndex].isActive = false
      const previous = currentStepIndex - 1
      const previousToSetActive = previous > updatedSteps.length - 1 ? 0 : previous
      updatedSteps[previousToSetActive].isActive = true
      state.registerStepper.steps = updatedSteps
    },
    setRegisterForm: (state, action: { payload: Partial<RegisterForm> }) => {
      state.registerStepper.form = { ...state.registerStepper.form, ...action.payload }
    },
  },
})

export const { setRegisterForm, resetRegisterStepper, previousRegisterStep, nextRegisterStep } = steppersSlice.actions

export const selectRegisterStepper = (state: RootState) => state.entities.steppers.registerStepper.steps
export const selectRegisterForm = (state: RootState) => state.entities.steppers.registerStepper.form
