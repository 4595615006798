import { useEffect, useState, useRef } from 'react'

type ProgressBarProps = {
  done: boolean
}

type ProgressStep = { value: number; delay: number }

function generateRandomProgression(
  steps: number,
  minDelay: number,
  maxDelay: number,
  minValue: number,
  maxValue: number,
  totalMaxDelay: number,
): ProgressStep[] {
  const progressSteps: ProgressStep[] = []
  let lastValue = minValue
  let lastDelay = 0
  let remainingTime = totalMaxDelay

  for (let i = 0; i < steps; i++) {
    let newValue = lastValue + Math.floor(Math.random() * ((maxValue - lastValue) / (steps - i)))
    newValue = Math.min(newValue, maxValue)

    const maxPossibleDelay = Math.min(maxDelay, remainingTime / (steps - i))
    const newDelay = lastDelay + Math.floor(Math.random() * (maxPossibleDelay - minDelay) + minDelay)
    remainingTime -= newDelay - lastDelay

    progressSteps.push({ value: newValue, delay: newDelay })

    lastValue = newValue
    lastDelay = newDelay
  }

  return progressSteps
}

function updateProgress(setProgress: (value: number) => void, timeoutsRef: React.MutableRefObject<NodeJS.Timeout[]>) {
  const progressSteps = generateRandomProgression(20, 500, 3000, 5, 99, 35000)

  progressSteps.forEach(({ value, delay }) => {
    const timeout = setTimeout(() => setProgress(value), delay)
    timeoutsRef.current.push(timeout)
  })
}

function ProgressBar({ done }: Readonly<ProgressBarProps>) {
  const [progress, setProgress] = useState(0)
  const timeoutsRef = useRef<NodeJS.Timeout[]>([])

  useEffect(() => {
    if (!done) {
      updateProgress(setProgress, timeoutsRef)
    } else {
      timeoutsRef.current.forEach(clearTimeout)
      timeoutsRef.current = []
      setProgress(100)
    }

    return () => {
      timeoutsRef.current.forEach(clearTimeout)
      timeoutsRef.current = []
    }
  }, [done])

  return (
    <div className="h-2 w-full overflow-hidden rounded-sm bg-gray-300">
      <div className="h-full bg-mintGreen transition-all duration-300" style={{ width: `${progress}%` }}></div>
    </div>
  )
}

export default ProgressBar
