import { DiscussionPanelInput } from '@/components/ds/DiscussionPanelInput.tsx'
import { useDiscussionPanelInput } from '@/components/hooks/use-DiscussionPanelInput.tsx'

export function DiscussionPanelInputContainer() {
  const { inputValue, onInputChange, isStreaming, onSubmit, messages, onRestartConversation, handleKeyDown } =
    useDiscussionPanelInput()
  return (
    <DiscussionPanelInput
      onRestartConversation={onRestartConversation}
      isSendingMessage={isStreaming}
      onSubmit={onSubmit}
      inputValue={inputValue}
      messages={messages}
      onInputChange={onInputChange}
      handleKeyDown={handleKeyDown}
    />
  )
}
