import { LegalWatchArticleGateway } from '@/gateways/interface/legalWatchArticle.gateway.ts'
import { LegalResourceGateway } from '@/gateways/interface/legalResource.gateway.ts'
import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { AccountGateway } from '../gateways/interface/account.gateway.ts'
import { AuthGateway } from '../gateways/interface/auth.gateway.ts'
import { ConversationGateway } from '../gateways/interface/conversation.gateway.ts'
import { ConversationSnapshotGateway } from '../gateways/interface/conversationSnapshot.gateway.ts'
import { FileGateway } from '../gateways/interface/file.gateway.ts'
import { SourceCollectionGateway } from '../gateways/interface/sourceCollection.gateway.ts'
import { TagGateway } from '../gateways/interface/tag.gateway.ts'
import { rootReducer } from './rootReducer.ts'
import { AppConfigGateway } from '@/gateways/interface/appConfig.gateway.ts'
import { DocumentAnalysisGateway } from '@/modules/document-analysis/domain/document-analysis.gateway.ts'

export type Dependencies = {
  authGateway: AuthGateway
  documentAnalysisGateway: DocumentAnalysisGateway
  conversationGateway: ConversationGateway
  conversationSnaphotGateway: ConversationSnapshotGateway
  sourceCollectionGateway: SourceCollectionGateway
  fileGateway: FileGateway
  tagGateway: TagGateway
  accountGateway: AccountGateway
  legalWatchArticleGateway: LegalWatchArticleGateway
  legalResourceGateway: LegalResourceGateway
  appConfigGateway: AppConfigGateway
}

export const createAppStore = (
  dependencies: Dependencies,
  preloadedState?: Partial<ReturnType<typeof rootReducer>>,
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
        serializableCheck: {
          // TODO: rework articles/setDateRangeFilter to use a serializable action
          ignoredActions: [
            'conversations/uploadFileInConversation/fulfilled',
            'articles/setDateRangeFilter',
            'messages/createConversationMessage/fulfilled',
            'toaster/setToasterMessage',
            'sourceCollections/setSelectedSourceCollectionsLabels',
          ],
          // TODO: rework entities.articles.filters.dateRange (to & from) to use a serializable path
          ignoredPaths: ['entities.articles.filters.dateRange', 'entities.toaster.toaster.toasterMessage'],
        },
        preloadedState,
      }),
  })
}
export const createTestAppStore = (
  dependencies: Partial<Dependencies>,
  preloadedState?: Partial<ReturnType<typeof rootReducer>>,
) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: dependencies,
        },
        serializableCheck: {
          ignoredActions: [
            'conversations/uploadFileInConversation/fulfilled',
            'messages/createConversationMessage/fulfilled',
          ],
        },
        preloadedState,
      }),
  })
}
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = ThunkDispatch<RootState, Dependencies, Action>
export type AppStore = ReturnType<typeof createAppStore>
