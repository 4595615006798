import { addMessage, streamLastMessageInConversation, setIsStreaming } from '@/store/messages/messagesSlice.ts'
import { TEMPORARY_AI_ID, TEMPORARY_HUMAN_ID } from '@/utils/CONVERSATION_CONSTANTS.ts'
import { Conversation } from '@/types/conversation.ts'
import { useAppDispatch } from '@/store/hooks.ts'
import { createConversationMessage } from '@/store/messages/useCases/createConversationMessage.ts'
import { listConversationMessages } from '@/store/messages/useCases/listConversationMessages.ts'

export const useAskQuestion = () => {
  const dispatch = useAppDispatch()

  const askQuestion = async (userInput: string, conversation: Conversation, sourceCollectionIds: string[]) => {
    const conversationId = conversation.id
    const question = userInput
    if (!userInput.length) return
    if (!conversation) return
    dispatch(setIsStreaming(true))

    dispatch(
      addMessage({
        content: userInput,
        id: TEMPORARY_HUMAN_ID,
        sources: [],
        type: 'HUMAN',
        createdAt: new Date().toISOString(),
        conversationId,
      }),
    )

    if (!conversation) return

    const reader = await dispatch(
      createConversationMessage({
        conversationId,
        content: question,
        sourceCollections: sourceCollectionIds,
      }),
    ).unwrap()

    if (!reader) return

    dispatch(
      addMessage({
        content: '',
        id: TEMPORARY_AI_ID,
        sources: [],
        type: 'AI',
        createdAt: new Date().toISOString(),
        conversationId,
      }),
    )

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        reader.releaseLock()
        dispatch(listConversationMessages(conversationId))
        dispatch(setIsStreaming(false))
        break
      }

      const token = await new Response(value).text()
      dispatch(
        streamLastMessageInConversation({
          conversationId,
          token,
        }),
      )
    }
  }

  return {
    askQuestion,
  }
}
