import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog.tsx'
import { ReactNode } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export function AuthModal({ isOpen, onClose, children }: Readonly<Props>) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle></DialogTitle>
      <DialogContent className="gap-0 pb-6 pl-8 pr-8 pt-8 sm:max-w-md" onInteractOutside={onClose}>
        {children}
      </DialogContent>
    </Dialog>
  )
}
